import track from './tracking';

// language files
// remember to add to supported languages below
import { nb } from '../translations/nb';
import { nn } from '../translations/nn';
import { smi } from '../translations/smi';
import { en } from '../translations/en';
import { ar } from '../translations/ar';
import { so } from '../translations/so';
import { ur } from '../translations/ur';

export const RTL_LANGUAGES = ['ar', 'ur'];
const SUPPORTED_LANGUAGES = { nb, nn, smi, en, ar, so, ur };

const DEFAULT_LANG = 'nb';
const DATA_ATTR = 'data-translate';
const DATA_HTML = 'data-html';

const noTranslation = (str) => `No translation found for ${str}`;

class Translation {
  constructor() {
    // get current language
    const lang = document.location.pathname.split('/')[1];
    const langSelector = document.querySelector('#lang-selector');
    const langList = document.querySelector('nav .lang-list');

    this.langButtons = document.querySelectorAll('nav .lang-list a');
    this.elements = document.querySelectorAll(`[${DATA_ATTR}]`);
    this.setLanguage(lang);

    // click language buttons
    this.langButtons.forEach((el) => {
      el.addEventListener('click', (evt) => {
        evt.preventDefault();
        const href = el.getAttribute('href');
        const langStr = el.textContent.toLowerCase();
        this.setLanguage(langStr);
        window.history.pushState(null, null, href);

        track.event(`språk_${el.textContent}`);
      });
    });

    window.addEventListener('click', (evt) => {
      if (evt.target !== langSelector) {
        langList.classList.remove('visible');
      }
    });

    langSelector.addEventListener('click', (evt) => {
      evt.preventDefault();
      langList.classList.toggle('visible');
    });
  }

  setMenuActive() {
    this.langButtons.forEach((el) => {
      if (el.textContent.toLowerCase() === this.lang) {
        el.parentNode.classList.add('current');
      } else {
        el.parentNode.classList.remove('current');
      }
    });
  }

  setLanguage(lang) {
    if (lang && SUPPORTED_LANGUAGES[lang]) {
      this.lang = lang;
    } else {
      this.lang = DEFAULT_LANG;
    }

    this.setMenuActive();
    this.insertTranslations();

    document.querySelector('html').setAttribute('lang', this.lang);
    document.querySelector(
      '#lang-selector'
    ).textContent = `Språk: ${this.lang}`;

    document.querySelector('nav .lang-list').classList.remove('visible');
  }

  translate(str, obj) {
    let props = str.split('.');
    let current = obj[props[0]];

    for (let i = 1; i < props.length; i++) {
      if (current) {
        let newVal = current[props[i]];
        if (newVal) {
          current = newVal;
        } else {
          current = null;
        }
      }
    }

    if (!current) {
      current = noTranslation(str);
    }

    return current;
  }

  insertTranslations() {
    const obj = SUPPORTED_LANGUAGES[this.lang];
    const isRTL = RTL_LANGUAGES.includes(this.lang);

    for (let el of this.elements) {
      const str = el.getAttribute(DATA_ATTR);
      const translation = this.translate(str, obj);
      const isHTML = el.getAttribute(DATA_HTML) !== null;
      const method = isHTML ? 'innerHTML' : 'textContent';
      if (isRTL) {
        el.setAttribute('dir', 'rtl');
      } else {
        el.removeAttribute('dir');
      }
      el[method] = translation;
    }
  }
}

export default new Translation();
