export const smi = {
  intro: {
    headline: 'Weeden&shy;senteret',
    text: 'Mii lea kannabis, ja mo dat váikkuha vuoiŋŋamaččaide? Jođe vulos lohkat eanet.',
  },
  plant: {
    headline: 'Dá lea cannabis-šaddu',
    text: 'Kannabis lea oktasašnamahus buot buktagiidda mat bohtet Cannabis sativa-šattus. Dán šattus gávdnojit olu variánttat. Eapmešattuid šaddogeahči ja gáhčči dat dábálepmosit geavahuvvojit kannabisas.',
    cta: 'Coahkkal šattu iešguđet osiid<br>vai beasat lohkat eanet',
    toppskudd: {
      title: 'Geahčeurbi',
      text: 'Geahčeurbi lea iešalddis eapmešattu lieđđeoassi. Kannabisšattus leat bás guolggažat main vuohču gáhči, ja gearhčeurbbi gáhččeguolggažiin lea eanemus THC-konsentrašuvdna, doaibmaávnnas kannabisas mii gárihuhttá. Geahčeurbi sáhttá goikaduvvot marihuana ráhkadeami várás. Geahčeurbbi gáhčči geavahuvvo ráhkadit hášša.',
    },
    stilk: {
      title: 'Nađđa',
      text: 'Nađđa guoddá šattu ja lieđi, ja das lea hui unnán THC.',
    },
    blad: {
      title: 'Lasttat',
      text: 'Šattu lasttat leat dovdosat miehtá máilmmi kannabisa universála symbolan, muhto dain lea dávjá hui unnán THC.',
    },
  },
  products: {
    headline: 'GÁVDNOJIT OLU KANNABIS-BUKTAGAT',
    text: 'Kannabis lea namahusčoahkki buot buktagiidda mat ráhkaduvvojit kannabisšattus. Dasa lassin gávdnojit kemiijalaččat ráhkaduvvon ávdnasat main leat kannabislágan váikkuhusat. Hunnplantena toppskuddat dat dábálaččat geavahuvvojit ráhkadit marihuana, muhto máŋggain osiin šattus leat maiddái váikkuhanávdnasat mat geavahuvvojit ráhkadit hášša ja iešguđetlágan oljjuid. Buot dát buktagat leat regulerejuvvon narkotihkkan, ja daid ii leat lohpi geavahit, ráhkadit, oastit, vuovdit ii ge vurkkodit.',
    cta: 'Deaddil daid iešguđetge<br>buktagiid lohkat eanet',
    hasj: {
      title: 'Hášša',
      text: 'Hášša lea gáhčči, suhkkes njalbi šattus, mii lea njuvdon čoahkkái kannabisšattu eará bázahusaiguin dassážii go šaddá ruškes čoaltu. Unna háššačolttoš mollejuvvo dávjá ja seaguhuvvo duhpáhiin ovdal go borgguhuvvo.',
    },
    cannabisolje: {
      title: 'Kannabisolju',
      text: 'Kannabisolju lea šaddoolju mii lea ekstraherejuvvon kannabisšattus luvvenávdnasa vehkiin. Oljjus lea hui olu THC ja hui unnán CBD. Dan sáhttá earret eará borgguhit, bidjat borramuššii, juhkat dahje geavahuvvot nuvttá goaikkanashámis.',
    },
    mat: {
      title: 'Biepmut ja juhkamušat',
      text: 'Kannabisa sáhttá maiddái bidjat biepmuide ja juhkamušaide (edibles), ovdamearkka dihtii browniesaide ja njálgáide. Jus geavaha kannabisa dán láhkai, de gárihuvvá mealgat maŋŋeleappos go jus borgguhivččii, áinnas okta gitta golbma tiimma maŋŋá, ja gárrendilli bistá dábálaččat guhkit. Lea stuorát riska ahte geavaha menddo olu kannabisa danne go olmmoš ii dovdda iežas gárihuvvamin ovdal go mealgat maŋŋá geavaheami.',
    },
    marihuana: {
      title: 'Marihuana',
      text: 'Marihuana, dahje weed, lea kannabisšattu lasttat ja lieđit. Dát dábálaččat goikaduvvojit ja millejuvvojit máisttakonsisteansa, ja borgguhuvvo nuvttá dahje ovttas duhpáhiin. Marihuana THC-sisdoallu rievddada, muhto dat lea eanaš unnit go háššas.',
    },
    cbdolje: {
      title: 'CBD-olju',
      text: 'CBD-olju lea ekstraherejuvvon kannabisšattus. Go buohtastahttá kannabisoljju ja CBD-oljju, de CBD-oljjus lea eanaš dušše CBD, ii ge THC, ja dat ii galggašii danne gárihuhttit, muhto dat sáhttá geavvat. Buot cohkodagat kannabisšattus, maiddái CBD-buktagat, leat klassifiserejuvvon narkotihkkan Norggas danne go dain leat rievddadalli THC-mearit.',
    },
    syntetisk: {
      title: 'Syntehtalaš cannabinoidat',
      text: 'Syntehtalaš kannabis, dahje spice, lea stuorra gárrenávnnasjoavku mii lea kemiijalaččat ráhkaduvvon, ja das leat kannabissullasaš váikkuhusat. Eatnašiin dáin ávdnasiin leat olu garraseappot váikkuhusat lunddolaš kannabisbuktagiid ektui nugo hášša ja marihuana, ja lea stuorát riska vásihit psykosa ja komplikašuvnnaid geavaheamis. Oahpes vahátváikkuhusat leat alla varradeaddu, alla pulsa, raddebákčasat, váibmodohppehat, dieđuhisvuohta, geasáhagat, ráfehisvuohta, ballu, panihkkadohppehallamat, akuhtta psykosat ja ovddeš dássedis psyhkalaš dávdda hedjoneapmi.',
    },
  },
  chemistry: {
    headline: 'Kannabisas leat máŋga kemiijalaš oasi',
    text: 'Kannabisšattus leat máŋga iešguđetlágan kemiijalaš ávdnasa. Daid mat váikkuhit vuoigŋamaččaide, mii gohčodat cannabinoidan. Dovdoseamos cannabinoidat leat THC ja CBD. Dát guokte dat stivrejit eanaš oasi kannabisa geavaheami vásihuvvon beavttu. Dát ávdnasat leat buoidáiluvvit ja sáhttet leat rupmašis guhká sorjavaš das man dávjá dan geavaha. THC ovttaskasgeavaheapmi dábálaččat duođaštuvvo varas gitta vahku, ja jus guhká geavaha olu, de hárve sáhttá guhkit duođaštit go ovtta mánu.',
    cta: 'Coahkkal iešguđet molekylaid vai beasat lohkat eanet',
    thc: {
      title: 'THC',
      text: 'THC (tetrahydrocannabinol) lea psykoaktiivvalaš ávnnas mii gárihuhttá. THC rievdada dan mo vuoigŋamaččat doibmet go lea gárihuvvan, ja dat sáhttá rievdadit miela, diđolašvuođa, láhttema dahje ahte oažžu eará duohtavuođaáddejumi. Go geavaha kannabisa mas lea olu THC, de badjána dearvvašvuođavahátlaš váikkuhusaid riska, omd. akuhtta psykosa.',
    },
    cbd: {
      title: 'CBD',
      text: 'CBD (cannabidiol) ii gárihuhte, muhto dat sáhttá váidudit sávakeahtes THC-váikkuhusaid nugo balu, muitinváttisvuođaid ja psykohtalaš symptomaid. Lea ain olu maid mii eat dieđe CBD birra, ja mat váikkuhusat das sáhttet leat, muhto dutkojuvvo olu dan birra. Buot CBD-buktagiin rievddada THC-mearri, ja danne dat lea regulerejuvvon narkotihkkan Norggas.',
    },
  },
  health: {
    headline: 'DEARVVAŠVUOĐAVÁIKKUHUS',
    text: 'Kannabis sáhttá leat váralaš vuoiŋŋamaččaide, erenoamážit nuorra vuoiŋŋamaččaide mat leat ahtanuššamin. Dábálaččat eai leat vuoigŋamaččat gárvásit ahtanuššan ovdal go olmmoš lahkona 25 jagi. Dutkit leat ovttaoaivilis das ahte vuoiŋŋamaččat mat leat ahtanuššamin, eai berre gillát kannabisgeavaheami. Kannabisgeavaheapmi sáhttá nannet riskka oažžut deprešuvnna dahje balu, erenoamážit hearkkes olbmuin ja jus geavahišgoahtá kannabisa nuorran. Go geavaha kannabisa, de sáhttá maiddái darvánit dasa.',
    cta: 'Loga eanet dearvvašvuođaváikkuhusaid<br>birra go deaddilat boaluid',
    korttid: {
      title: 'Oanehisáiggeváikkuhusat',
      text: `
          <p>Eatnašat geat borgguhit hášša dahje marihuana, eai oaččo psyhkalaš váttisvuođaid.</p>
          <p>Lea stuorra erohus das mo reagere kannabisii. Muhtumat sáhtte moiddodit, baláskit, oažžut váibmogirddáhaga ja panihkkaluvvat dahje šlundut, ja earát ges dovdet lotkkodeami, geahpehusa, miela badjáneamen dahje borranmiela. Dát sáhttá maiddái rievdat juohke geavaheamis, ja dábálaččat nohket dát dovddut go olmmoš čielgagoahtá.</p>
          <p>Kannabisgeavaheapmi sáhttá maiddái váikkuhit kognitiiva funkšuvnnaide nugo áicilvuhtii ja reakšuvdnadáidui, ja earret eará danne ii galgga vuodjit biillain go lea gárihuvvan. Jus geavaha dan eará narkotihkkaávdnasiiguin ja alkoholain, de sáhttet váikkuhusat garrat ja addit einnoskeahtes reakšuvnnaid.</p>
        `,
    },
    langtid: {
      title: 'Guhkesáiggeváikkuhusat',
      text: `
          <p>Jámma kannabisgeavaheamis guhkit áigge badjel sáhttet leat eanet guhkesáigásaš váikkuhusat. Okta váikkuhus lea hedjonan kognitiiva funkšuvdna, nugo hedjonan áicilvuohta, muitu ja doaibmačoavdindáidu.</p>
          <p>Danne go nuorra vuoiŋŋamaččat eai leat gárvásit ahtanuššan, de sáhttet dákkár vahágat šaddat bissovažžan go geavaha olu kannabisa nuorran. Guhkesáiggegeavaheapmi sáhttá maiddái dagahit psyhkalaš váttisvuođaid nugo balu ja deprešuvnna, ja psykosasymptomaid nugo hallusinašuvnnaid ja jorggunavdduid. Dát guoská erenoamážit hearkkes olbmuide. Vearrámus dilis sáhttá guhkit áigge kannabisgeavaheapmi dagahit guhkesáigásaš psykosaid nugo schizofreniija.</p>
        `,
    },
    avhengighet: {
      title: 'Darváneapmi',
      text: `
          <p>Jus nuorran borgguhišgoahtá kannabisa ja geavaha dan olu, de lea stuorát riska darvánit dasa. Jus borgguhat olu, de rumaš gierdagoahtá eanet, ja olmmoš dárbbaša dađistaga eanet kannabisa vai gárihuvvá.</p>
          <p>Darváneapmi mearkkaša ahte go don geahččalat heaitit, de sáhtát vásihit iešguđetlágan abstineanssaid. Mášohisvuohta, oađđenváttisvuođat, ballu ja konsentrašuvdnaváttut leat dábálaš symptomat. Go lea darvánan, de ii dárbbaš álo geavahit kannabisa gárihuvvama dihtii, muhto garvin dihtii abstineanssaid ja dovdat iežas "dearvvasin".</p>
          <p>Darváneami nubbi bealli lea ahte dat sáhttá čuohcat juogamasa mii lea dehálaš dutnje, ja ahte don válljet kannabisa earáid doaimmaid sadjái. Dál eambbosat ohcet veahki beasset eret kannabisgeavaheamis go ovdal.</p>
        `,
    },
  },
  smuggling: {
    headline: 'GOS KANNABIS BOAHTÁ?',
    text1:
      'Álgoálggus šaddaduvvui šaddu lieggasat guovlluin, erenoamážit Ásias, Davvi-Afrihkás ja Gaska-Amerihkás, ja doppe dat šaddaduvvo ain olu. Hášša mii duoguštuvvo Norggas, boahtá eanaš Marokkos ja njágaldahttojuvvo deike Espánnja ja Hollándda bokte, muhto eanet ahte eanet kannabis mii geavahuvvo Eurohpás, šaddaduvvo maiddái dáppe. Maiddái Norggas leat almmustahttojuvvon máŋggat kannabisplantášat.',
    text2:
      'Kannabisšaddadeamis sáhttet leat stuorra negatiivvalaš biras- ja servodatlaš váikkuhusat. Dat sáhttá hehttet olbmuid fidnemis čázi ja leat mielde luoitimin birasváralaš kemikálaid. Lobihis kannabisgávppašeapmi lea okta dain deháleamos vugiin mo organiserejuvvon rihkolaččat ja terrororganisašuvnnat ruhtadit eará lobihis doaimmaid, nugo olmmošgávppašeami ja eará lobihis ávdnasiid ja vearjjuid oastima.',
  },
  smugglingGame: {
    text: 'Coahkkal boalu vuolábealde stivren dihtii girdi Norgga guvlui. Geahččal deaivat alit rieggáid. Deaddil šearpma olgeš dahje gurut bealde stivret.',
    cta: 'Speallu',
    game: {
      text1: 'Hášša mii duoguštuvvo Norggas boahtá dávjjimusat Marokkos.',
      text2: 'Dat buktojuvvo suoli deike Espánnja ja Nederlándda bokte.',
      text3:
        'Dađi stuorát oassi dan cannabisas mii geavahuvvo Eurohpás lea maiddá gilvojuvvon dáppe.',
      text4: 'Maiddá Norggas leat gávdnan máŋga cannabisgilvvabáikki.',
    },
  },
  sale: {
    headline: 'Garrasat gárrenávnnas go ovdal',
    text: 'Hášša ja marihuana leat šaddan olu garrasat gárrenávnnasin go ovdal danne go THC-mearri lea lassánan. Hášša gaskamearálaš THC-mearri lea lassánan su. 10 %:s logi jagi áigi gitta 30 %:i dál, muhto leat registrerejuvvon THC-mearit gitta 45 %:i. Eanet THC-mearri addá eanet riskka akuhtta ja guhkesáigásaš dearvvašvuođavahágiidda. Oainnát go iešguđet jointaid givrodaga?',
    cta: 'Deaddil ovtta jointa ja geahča vástádusa',
    joints:
      'Vaikke vel Norgga gaskamearalaš lohku lea 30 % allat, lea mihtiduvvon nu alla lohku go 45 %.',
  },
  statistics: {
    headline: 'MAN OLUSAT GEAVAHIT KANNABISA NORGGAS?',
    text: 'Olusat dáidet jáhkkit <i>buohkaid</i> geahččalan kannabisa, ja muhtun birrasiin dat lea dábálat go eará birrasiin. Guorahallamat nuoraid gaskkas čájehit ahte 91 % eai leat geahččalan kannabisa. Ungdata-guorahallan (2020) čájeha ahte nuoraidceahkis lea 5 % gánddain ja 3 % nieiddain geahččalan kannabisa maŋimuš jagis. Joatkkaskuvllas muitalit eanet oahppit iežaset geahččalan: 19 % gánddain ja 11 % nieiddain vástidit iežaset geahččalan maŋimuš jagi. Oslos (2018) leat logut alleleappot go muđui riikkas, ja Jo3:s Oslos leat 38 % gánddain ja 23 % nieiddain geahččalan kannabisa. Eurohpálaš skuvlaguorahallama vuođul lea norgga nuoraid kannabisgeavaheami unnimusaid searvvis Eurohpás. 2021 Ungdata-logut čájehit veahá unnit geavaheami, muhto dan ferte geahččat oktilaččat njoammudávdda eastadandoaibmabijuiguin Covid-19 áigge.',
    numbers: {
      stat1: 'nuorain <strong>eai</strong> leat geahččalan',
      stat2: 'gánddain joatkkaskuvllas leat geahččalan',
      stat3: 'nieiddain joatkkaskuvllas leat geahččalan',
    },
  },
  prohibited: {
    headline: 'KANNABISGEAVAHEAPMI LEA GILDOJUVVON',
    text1:
      'Narkotihkkaláhkaásahus cealká ahte kannabis lea narkotihkka, ja dálkkaslágas ges lea mearriduvvon ahte narkotihka ráhkadeapmi, háhkan, vuovdin, importa, eksporta, vurkkodeapmi, háldu ja geavaheapmi leat gildojuvvon. Buot buktagat mat ráhkaduvvot kannabisšattus, leat regulerejuvvon narkotihkkan ja leat dál gildojuvvon. Maiddái kemiijalaš ráhkaduvvon kannabis, syntehtalaš cannabinoidat, adnojuvvojit narkotihkkan ja lea gildojuvvon. Dálkkaslága rihkkun lea ráŋggáštusvuloš. Nuorat geat váldojit kannabisgeavaheamis, dávjá ožžot molssaevttolaš reakšuvnna politiijain. Nuorat sáhttet oažžut áššáskuhttinheaitaga, mii mearkkaša ahte politiijat eai čuoččáldahte ráŋggáštusássi, muhto ovdamearkka dihtii gáibidit gužžaiskosa ja sosiálabargiid sin čuovvolit. Muhtun medisiinnalaš kannabisbuktagiidda sáhttá doavttir addit reseapta muhtun pasieanttaide geaidda dat sáhttá leat ávkkálaš. Dát lea garrasit regulerejuvvon.',
    text2:
      'Máŋggat bellodagat Stuorradikkis háliidit ahte servodatreakšuvnnaid ovddasvástádus lobihis gárrenávdnasiid geavaheapmái ja vurkkodeapmái iežas atnui sirdojuvvo justiisasuorggis dearvvašvuođabálvalussii. Sii háliidit dán láhkai sihkkarastit buoret veahki ja čuovvoleami. Kannabis lea ain gildojuvvon dál.',
    text3:
      'Stuorradiggi lea mearridan ahte gárrenávnnasgeavaheami olbmuid gaskkas geat veahkehit dahje riŋgejit heahtebálvalusaide akuhttadilis, nugo badjelmearegeavaheamis, eai galgga politiijat ráŋggáštit.',
  },
  links: {
    headline: 'Leago ain juoga man háliidivččet diehtit?',
    text: 'Háliidatgo diehtit vel eanet cannabisa birra ja mo dat sáhttá váikkuhit vuoiŋŋamaččaide ja rupmašii, dahje dárbbašat go dušše háleštit geainna nu? Dás oaččut muhttin ávkkálaš liŋkkaid maid sáhtát geavahit.',
    readmore: 'Loga eanet',
    ung: 'Almmolaš ja kvaliteahttasihkkaraston dieđut nuoraide. Maiddá dieđut cannabis ja eará gárrenávdnasiid birra. Sáhtát maiddá jearrat jearaldagaid ja oažžut vástádusaid iežat jearaldagaide.',
    rustelefonen:
      'Riikkaviidosaš diehtojuohkinbálvalus gárrenávnnasproblematihka birra. Doppe leat ollu dieđut cannabis birra. Sáhtát váldit anonyma oktavuođa telefovnna, čálašeami dahje jearaldatskovi bokte.',
    mentalhelse:
      'Beroštusorganisašuvdna nuoraide man doaibma lea eastadit psykalaš váttuid. Jus dus lea dárbu geainna ge háleštit sáhtát váldit oktavuođa sin čálašanbálvalusa bokte.',
    helsenorge:
      'Almmolaš neahttabáiki gos leat dearvvašvuođadieđut buohkaide. Doppe leat maiddá dieđut cannabis birra.',
  },
  about: {
    headline: 'Kampánjja birra',
    text: '<p>Dál go nuorain leat nohkameahttun dieđut olámuttos interneahtas, gálduin mat eai álo leat luohtehahtti, gáibiduvvo eanet Dearvvašvuođadirektoráhtas fága- ja eiseváldeorgánan. Leat ollu myhtat cannabisa birra – mat sihke muitalit ahte ávnnas ii leat váralaš, ja dan ahte lea váraleabbo go rievtti mielde sáhttá leat. Weedenguovddáš/Weedensenteret galgá danne doaibmat digitála diehtoguovddážin cannabisa birra, das leat dásseduvvon dieđut mat sáhttet dagahit ahte nuorat ulbmiljoavku oažžu ipmárdusa das ahte cannabis sáhttá mielddisbuktit riskka go dan geavaha nuorran. Kampánnjas mis lea guovddážis addit máŋggabeallásaš dieđuid faktaid vuođul, iige dieđuid mat galget baldit, vai kampánja sáhttá dahkat nuoraid dihtomielalažžan dan birra ahte mo ja goas sáhttá cannabis leat váttisvuohtan – sihke alcces ja ustibiidda sin birrasis. Eanet luohttámuša ja rabasvuođa sáhttit mii dušše olahit gulahallama bokte.</p>',
  },
  cookies: {
    short:
      'Dát neahttasiidu geavaha <a href="https://www.helsedirektoratet.no/om-oss/om-nettstedet/besoksstatistikk-og-informasjonskapsler" target="_blank" rel="noopener">diehtočoahkuid</a> (gáhkožiid)',
    long: 'Weedensenteret er en kampanje som skal informere og bevisstgjøre på når og hvordan rusmidler kan bli et problem. Vi minner om at alle produkter av cannabisplanten er regulert som narkotika og er forbudt å bruke, lage, kjøpe, selge eller oppbevare.',
  },
  ui: {
    close: 'Govčča',
    //about: 'Kampánjja birra',
    about: 'Birra',
    //videos: 'Geahča filmmaid',
    videos: 'Filmmat',
  },
  contentmenu: {
    forsiden: 'Ovdasiidu',
    planten: 'Šaddu',
    produkter: 'Buktagat',
    kjemi: 'Kemiija',
    helse: 'Váikkuhusat dearvvašvuhtii',
    smugling: 'Suollemas fievrrideapmi',
    salg: 'Vuovdin',
    statistikk: 'Statistihkka',
    lovverk: 'Láhkaortnet',
    lenker: 'Liŋkkat',
  },
};
