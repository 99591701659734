/**
 * This file is part of the Helsedirektoratet Cannabis 2020 application.
 *
 * (c) APT AS
 *
 * For the full copyright and license information, please view the LICENSE
 * file that was distributed with this source code.
 */

import WebGL from './webgl/main';
import renderer from './webgl/renderer';
import scene from './webgl/scene';
import camera from './webgl/camera';
import * as assetList from './assetlist';
import controller from './controller';
import sections from './sections';
import loader from './loader';

import './sidenav';
import './videos';
import './about';
import './translation';
import './cookies';
import './logo';

function init() {
  const onProgress = (p) => {
    loader.progress(p);
  };
  const onComplete = () => {
    // avoid jank
    loader.complete(() => {
      controller.register(sections);
      new WebGL();
    });
  };

  // initial renderer to get the correct color
  renderer.render(scene, camera);
  assetList.loadAll(renderer, onProgress, onComplete);
}

init();
