import {
  CubeTextureLoader,
  MeshBasicMaterial,
  MeshPhysicalMaterial,
  Vector3,
} from 'three';
import gsap from 'gsap';

import BaseInstance from './baseinstance';
import assets from '../../assetloader';
import pointer from '../pointer';

import Hotspots from '../hotspots';

import tableWithObjects from 'gltf/table.glb';
import weedbag from 'gltf/weedbag.glb';
import tableTexture from 'textures/table-bake.jpg';
import kolbeTexture from 'textures/kolbe-ext-texture.jpg';
import brownieTex from 'textures/brownie-tex.jpg';
import dryppeflaskeParticlesCombined from 'textures/dryppflaske-particles-combined.jpg';
import weedBowlTex from 'textures/weed-bowl-tex.jpg';
import weedbagTexture from 'textures/weedbag-tex.jpg';

import envMapImage from 'textures/env-map.jpg';

export default class Products extends BaseInstance {
  constructor(opts) {
    super(opts);
  }

  init() {
    this.setupTable();
    this.setupMaterials();
    this.setupHotspots();
    this.setupAnimations();
  }

  setupTable() {
    this.sceneContainer = assets.get(tableWithObjects).scene.children[0];
    this.sceneContainer.scale.set(400, 400, 400);
    this.sceneContainer.position.set(0, -3.5, 0);
    this.sceneContainer.rotation.set(0.3, 1, 0);

    this.add(this.sceneContainer);
  }

  setupMaterials() {
    // envmap
    const loader = new CubeTextureLoader();

    const envMap = loader.load([
      envMapImage,
      envMapImage,
      envMapImage,
      envMapImage,
      envMapImage,
      envMapImage,
    ]);

    // table
    const table = this.sceneContainer.getObjectByName('BORD');
    const tableMaterial = new MeshBasicMaterial({
      map: assets.get(tableTexture),
      color: 0xeeeeee,
    });
    table.material = tableMaterial;

    // hasjklump
    const hasjKlumper = this.sceneContainer.getObjectByName('HASJ_KLUMP001');
    const klumpMaterial = new MeshBasicMaterial({
      map: assets.get(brownieTex),
    });

    hasjKlumper.material = klumpMaterial;

    const weedBowl = this.sceneContainer.getObjectByName('Cylinder002');
    weedBowl.material = new MeshBasicMaterial({ map: assets.get(weedBowlTex) });

    // joint
    // const joint = this.sceneContainer.getObjectByName('JOINT');
    // const jointMaterial = new MeshLambertMaterial({ color: 0xcccccc });
    // joint.material = jointMaterial;

    // kolbe
    const kolbe = this.sceneContainer.getObjectByName('KJEMIKOLBE');
    const kolbeMaterial = new MeshPhysicalMaterial({
      map: assets.get(kolbeTexture),
      color: 'white',
      transparent: true,
      opacity: 0.75,
      roughness: 0,
      metalness: 0,
      envMap,
      reflectivity: 1.5,
    });
    kolbe.material = kolbeMaterial;

    // brownie
    const brownie = this.sceneContainer.getObjectByName('BROWNIE');

    brownie.material = new MeshBasicMaterial({ map: assets.get(brownieTex) });

    // dryppeflaske
    const flaske = this.sceneContainer.getObjectByName('DRYPPFLASKE');

    // flaske1.material = new MeshLambertMaterial({ color: 0x4a91b5 });
    flaske.material = new MeshBasicMaterial({
      map: assets.get(dryppeflaskeParticlesCombined),
    });

    // bong
    // const bong = this.sceneContainer.getObjectByName('BONG');
    // const bongMaterial = new MeshBasicMaterial({
    //   map: assets.get(bongTex),
    //   color: 'white',
    //   envMap,
    //   reflectivity: 0.5,
    // });
    // bong.material = bongMaterial;

    // smuldrepartikler
    const particles = this.sceneContainer.getObjectByName(
      'smuldre_partikkel_2027'
    );
    particles.material = new MeshBasicMaterial({
      map: assets.get(dryppeflaskeParticlesCombined),
    });

    // weedbag
    const weedbagModel = assets.get(weedbag).scene.children[0].children[0];
    const weedbagMap = assets.get(weedbagTexture);
    weedbagModel.material = new MeshBasicMaterial({
      color: 'white',
      map: weedbagMap,
    });
    this.sceneContainer.add(weedbagModel);
  }

  setupHotspots() {
    const hotspotItems = [
      {
        pos: new Vector3()
          .copy(this.sceneContainer.getObjectByName('HASJ_KLUMP001').position)
          .add(new Vector3(-0.0005, 0, 0)),
        el: document.querySelector('#hotspotKlump'),
      },
      {
        pos: new Vector3()
          .copy(this.sceneContainer.getObjectByName('KJEMIKOLBE').position)
          .add(new Vector3(-0.002, 0.0005, 0.0006)),
        el: document.querySelector('#hotspotKolbe'),
      },
      {
        pos: new Vector3()
          .copy(this.sceneContainer.getObjectByName('BROWNIE').position)
          .add(new Vector3(0, -0.001, 0)),
        el: document.querySelector('#hotspotBrownie'),
      },
      {
        pos: new Vector3()
          .copy(this.sceneContainer.getObjectByName('DRYPPFLASKE').position)
          .add(new Vector3(0.003, 0, 0.0015)),
        el: document.querySelector('#hotspotJoint'),
      },
      {
        pos: new Vector3()
          .copy(this.sceneContainer.getObjectByName('DRYPPFLASKE').position)
          .add(new Vector3(-0.0006, 0, 0.0015)),
        el: document.querySelector('#hotspotFlaske'),
      },
      {
        pos: new Vector3()
          .copy(
            this.sceneContainer.getObjectByName('smuldre_partikkel_2027')
              .position
          )
          .add(new Vector3(0, 0, 0)),
        el: document.querySelector('#hotspotParticles'),
      },
    ];

    this.hotspots = new Hotspots({
      items: hotspotItems,
      target: this.sceneContainer.getObjectByName('BORD'),
      debug: false,
      debugScale: 0.0005,
      zoomFactor: 16,
    });
  }

  setupAnimations() {
    this.sceneContainer.position.x = window.innerWidth > 768 ? 2 : 0;

    this.inAnim = gsap.timeline({ paused: true });
    const tl = gsap.timeline({
      paused: true,
    });
    this.outAnim = gsap.timeline({ paused: true });

    if (window.innerWidth > 768) {
      this.inAnim.from(
        '#products',
        { y: '10vh', opacity: 0, duration: 1.5, ease: 'power2.out' },
        2
      );

      tl.to(
        '#products',
        {
          x: '-50vw',
          opacity: 0,
          ease: 'power3.in',
          duration: 0.6,
        },
        0.2
      );

      this.inAnim.to(
        this.sceneContainer.position,
        { y: -4, z: 0, ease: 'none', duration: 1 },
        1
      );

      tl.to(
        this.sceneContainer.rotation,
        { y: 0, ease: 'power4.inOut', duration: 1.5 },
        0
      );
      tl.to(
        this.sceneContainer.position,
        { z: 10, ease: 'power2.inOut', duration: 1 },
        0
      );
      tl.to(
        this.sceneContainer.position,
        { x: -4, ease: 'power2.inOut', duration: 1.5 },
        1
      );
    } else {
      this.inAnim.fromTo(
        '#products',
        { y: '100vh' },
        { y: '-100vh', ease: 'none', duration: 3 },
        1
      );

      this.inAnim.to(
        this.sceneContainer.position,
        { y: -4, ease: 'none', duration: 1 },
        1
      );

      tl.to(
        this.sceneContainer.rotation,
        { y: 0, ease: 'power4.inOut', duration: 1.5 },
        0
      );
      tl.to(
        this.sceneContainer.position,
        { z: 10, x: 3, ease: 'power2.inOut', duration: 1 },
        0
      );
      tl.to(
        this.sceneContainer.position,
        { x: -3.5, ease: 'power2.inOut', duration: 1.5 },
        1
      );
    }

    tl.from(
      '#productHotspots',
      {
        opacity: 0,
        ease: 'none',
        duration: 0.2,
      },
      0.2
    );

    this.outAnim.to(
      this.sceneContainer.position,
      { y: 10, z: -5, x: 0, ease: 'power3.inOut', duration: 1 },
      0.1
    );

    this.outAnim.to(
      this.sceneContainer.rotation,
      { y: 1, x: 1, z: 0, ease: 'power2.inOut', duration: 1 },
      0
    );

    this.outAnim.to(
      '#productHotspots',
      {
        opacity: 0,
        ease: 'none',
        duration: 0.1,
      },
      0
    );

    this.animations = [
      {
        from: 0,
        to: 1500,
        instance: tl,
      },
    ];

    this.totalAnimFrames = this.calculateAnimationFrames();
  }

  onRaf() {
    if (!this.active) return;

    this.rotation.y = -pointer.normEased.x * 0.03;
    // this.rotation.x = -pointer.normEased.y * 0.12;

    this.hotspots.update();
  }
}
