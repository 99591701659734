import { Object3D, MeshLambertMaterial, Vector3, Euler } from 'three';
import gsap from 'gsap';

import dispatcher from '../dispatcher';

import BaseInstance from './baseinstance';
import assets from '../../assetloader';
import pointer from '../pointer';

import camera from '../camera';
import Hotspots from '../hotspots';

import moleculeUrl from 'gltf/molecule.glb';
import leafUrl from 'gltf/leaf.glb';

export default class Health extends BaseInstance {
  constructor(opts) {
    super(opts);
  }

  init() {
    this.container = new Object3D();
    this.add(this.container);

    this.setupAnimations();
    this.setupHotspots();
    this.addAssets();
  }

  setFilter(id) {
    const settings = {
      waveFactor: 0,
      shiftAmount: 0,
      speed: 0,
      enabled: true,
    };

    if (id === 'short-term') {
      settings.waveFactor = 0.005;
      settings.shiftAmount = 0;
      settings.speed = 0;
    }

    if (id === 'long-term') {
      settings.waveFactor = 0.01;
      settings.shiftAmount = 0.009;
      settings.speed = 0.2;
    }

    if (id === 'dependency') {
      settings.waveFactor = 0.015;
      settings.shiftAmount = 0.01;
      settings.speed = 0.8;
    }

    if (id === 'complete') {
      settings.waveFactor = 0;
      settings.shiftAmount = 0;
      settings.speed = 0;
      settings.enabled = false;
    }

    dispatcher.trigger({ name: 'shiftToggle' }, { ...settings });
  }

  addAssets() {
    const { pxToUnits } = camera;
    const halfW = window.innerWidth * 0.5;
    const halfH = window.innerHeight * 0.5;

    const bottomLeft = new Vector3(-halfW * pxToUnits, -halfH * pxToUnits, 0);
    const topLeft = new Vector3(-halfW * pxToUnits, halfH * pxToUnits, 0);
    const bottomRight = new Vector3(halfW * pxToUnits, -halfH * pxToUnits, 0);
    const topRight = new Vector3(halfW * pxToUnits, halfH * pxToUnits, 0);
    const left = new Vector3(-halfW * pxToUnits, 0, 0);
    const right = new Vector3(halfW * pxToUnits, 0, 0);

    const leaf = assets.get(leafUrl).scene.children[0];
    const molecule = assets.get(moleculeUrl).scene.children[0];
    const mat = new MeshLambertMaterial({ color: 0x007e7b });

    const objects = [
      {
        model: leaf,
        pos: topLeft,
        offset: new Vector3(-1, -1.5, 0),
        scale: new Vector3(0.3, 0.3, 0.3),
        rotation: new Euler(1.5, 1, 0),
      },
      {
        model: leaf,
        pos: topRight,
        offset: new Vector3(-1, 0, 0),
        scale: new Vector3(0.3, 0.3, 0.3),
        rotation: new Euler(1.5, -0.5, 0),
      },
      {
        model: molecule,
        pos: bottomLeft,
        offset: new Vector3(1, 1, 0),
        scale: new Vector3(2, 2, 2),
        rotation: new Euler(1.5, -0.5, 0),
      },
      {
        model: molecule,
        pos: bottomRight,
        offset: new Vector3(-2, 2, 0),
        scale: new Vector3(2, 2, 2),
        rotation: new Euler(0, 0, 0),
      },
      {
        model: molecule,
        pos: left,
        offset: new Vector3(0, 0, 0),
        scale: new Vector3(2, 2, 2),
        rotation: new Euler(0, 0, 0),
      },
      {
        model: leaf,
        pos: right,
        offset: new Vector3(0, 1, 0),
        scale: new Vector3(0.3, 0.3, 0.3),
        rotation: new Euler(1.5, -1.5, 0),
      },
    ];

    for (let obj of objects) {
      const { pos, offset, scale, rotation, model } = obj;
      const mesh = model.clone();
      mesh.material = mat;
      mesh.position.copy(pos).add(offset);
      mesh.scale.copy(scale);
      mesh.rotation.copy(rotation);
      this.container.add(mesh);
    }

    // molecule.scale.set(2, 2, 2);
    // molecule.position.set(topRight.x, topRight.y, 0);
    // console.log(camera.pxToUnits);
    // this.container.add(molecule);
  }

  setupAnimations() {
    this.container.position.x = window.innerWidth > 768 ? 2 : 0;

    this.inAnim = gsap.timeline({ paused: true });
    this.outAnim = gsap.timeline({ paused: true });

    this.inAnim.from('#healthHotspots', { opacity: 0, duration: 0.5 }, 1);
    this.outAnim.to('#healthHotspots', { opacity: 0, duration: 0.15 }, 0);

    if (window.innerWidth > 768) {
      this.inAnim.from(
        '#health',
        { y: '20vh', opacity: 0, duration: 1.5, ease: 'power2.out' },
        1
      );
      this.outAnim.to(
        '#health',
        { y: '-20vh', opacity: 0, duration: 0.1, ease: 'power2.in' },
        0
      );
    } else {
      this.inAnim.fromTo(
        '#health',
        { y: '100vh' },
        { y: '-100vh', ease: 'none', duration: 2 },
        0
      );
    }
  }

  setupHotspots() {
    const onClose = () => {
      this.setFilter('complete');
    };
    const hotspotItems = [
      {
        pos: new Vector3(-2, 4, -2),
        el: document.querySelector('#hotspotShortterm'),
        onOpen: () => {
          this.setFilter('short-term');
        },
        onClose,
      },
      {
        pos: new Vector3(0.5, 1, 1),
        el: document.querySelector('#hotspotLongterm'),
        onOpen: () => {
          this.setFilter('long-term');
        },
        onClose,
      },
      {
        pos: new Vector3(-2.5, -1.3, -1),
        el: document.querySelector('#hotspotDependency'),
        onOpen: () => {
          this.setFilter('dependency');
        },
        onClose,
      },
    ];

    this.hotspots = new Hotspots({
      items: hotspotItems,
      target: this.container,
      debug: false,
      debugScale: 0.5,
      zoomFactor: 19,
    });
  }

  onRaf() {
    if (!this.active) return;

    this.rotation.y = -pointer.normEased.x * 0.4;
    this.rotation.x = -pointer.normEased.y * 0.4;

    this.hotspots.update();
  }
}
