import sidenav from './sidenav';
import controller from './controller';

class Logo {
  constructor() {
    const el = document.getElementById('logo');

    el.addEventListener('click', () => {
      sidenav.toggleActive(0);
      controller.setIndex(0, 0);
    });
  }
}

export default new Logo();
