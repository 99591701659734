export const nn = {
  intro: {
    headline: 'Weeden&shy;senteret',
    text: 'Kva er cannabis og korleis verkar det på hjernen? Bla nedover for å få vite meir.',
  },
  plant: {
    headline: 'Dette er Cannabis-planten',
    text: 'Cannabis er ei fellesnemning på alle produkt som kjem frå planten Cannabis sativa. Det finst mange variantar av denne planten. Det er hovudsakleg toppskota og plantekvaa frå hoplantane som blir nytta i cannabis.',
    cta: 'Klikk på dei ulike delane av planten<br>for å lese meir',
    toppskudd: {
      title: 'Toppskot',
      text: 'Toppskotet er sjølve blomsterdelen av hoplanten. På cannabisplanten finst det bittesmå kjertelhår fylte med harpiks, og i kjertelhåra på toppskotet finn vi den høgaste konsentrasjonen av THC, verkestoffet i cannabis som gir rus. Toppskotet kan ein tørke for å lage marihuana. Plantekvaa frå toppskotet blir brukt for å lage hasj.',
    },
    stilk: {
      title: 'Stilk',
      text: 'Stilken ber planten og blomsteren, og inneheld svært lite THC.',
    },
    blad: {
      title: 'Blad',
      text: 'Blada på planten er kjende over heile verda som eit universelt symbol for cannabis, men dei inneheld ofte svært lite THC.',
    },
  },
  products: {
    headline: 'Det finst mange cannabis-produkt',
    text: 'Cannabis er ei samlenemning for alle produkta som blir framstilte av cannabisplanten. I tillegg finst det kjemisk framstilte stoff med cannabisliknande verknader. Det er hovudsakleg toppskota frå hoplanten ein nyttar til marihuana, men fleire delar av planten inneheld også verkestoff som blir nytta for å lage hasj og ulike oljar. Alle desse produkta er regulerte som narkotika og er i dag forbodne å bruke, lage, kjøpe, selje eller oppbevare.',
    cta: 'Klikk på dei ulike produkta<br>for å lese meir',
    hasj: {
      title: 'Hasj',
      text: 'Hasj er kvae, ei seig væske frå planten, som er pressa saman med andre restar av cannabisplanten til det blir ein brun klump. Ein liten hasjklump blir ofte smuldra opp og blanda med tobakk før han blir røykt.',
    },
    cannabisolje: {
      title: 'Cannabisolje',
      text: 'Cannabisolje er planteolje som er trekt ut frå cannabisplanten ved hjelp av eit løysemiddel. Oljen inneheld svært mykje THC og svært lite CBD. Denne oljen kan ein mellom anna røyke, tilsetje i mat eller ta reint i dråpeform.',
    },
    mat: {
      title: 'Mat- og drikkevarer',
      text: 'Cannabis kan også puttast i drikke og mat (edibles), til dømes i brownies og godteri. Tek ein cannabis på denne måten, kjem ruseffekten mykje seinare enn ved røyking, gjerne éin til tre timar etterpå, og varar som regel lenger. Det er større risiko for at ein får i seg for mykje cannabis fordi ein ikkje kjenner rusen før lenge etter inntaket.',
    },
    marihuana: {
      title: 'Marihuana',
      text: 'Marihuana, weed eller grønt, er blad og blomstrar frå cannabisplanten. Desse blir vanlegvis tørka og kverna opp til ein krydderkonsistens, og røykast åleine eller saman med tobakk. THC-innhaldet i marihuana varierer, men det er stort sett lågare enn i hasj.',
    },
    cbdolje: {
      title: 'CBD-olje',
      text: 'CBD-olje er olje trekt ut frå cannabisplanten. Til forskjell frå cannabisolje inneheld han stort sett berre CBD og ikkje THC og skal difor ikkje gi nokon rus, men dette kan variere. Alle ekstrakt av cannabisplanten, også CBD-produkt, er klassifiserte som narkotika i Noreg fordi dei inneheld varierande mengder med THC.',
    },
    syntetisk: {
      title: 'Syntetiske cannabinoider',
      text: 'Syntetisk cannabis, eller spice, er ei stor gruppe rusmiddel som er kjemisk produsert og har cannabisliknande effektar. Dei fleste av desse stoffa har mykje sterkare verknader samanlikna med naturlege cannabisprodukt som hasj og marihuana, og med høgare risiko for å oppleve psykose og komplikasjonar ved inntak. Kjente skadeverknader er høgt blodtrykk, høg puls, brystsmerter, hjarteinfarkt, medvitsløyse, krampar, uro, angst, panikkanfall, akutte psykosar og forverring av tidlegare stabil psykisk sjukdom.',
    },
  },
  chemistry: {
    headline: 'Cannabis inneheld fleire kjemiske komponentar',
    text: 'Cannabisplanten inneheld mange ulike kjemiske stoff. Dei som verkar på hjernen kallar vi cannabinoid. Dei mest kjende cannabinoida er THC og CBD. Det er desse to som bestemmer store delar av effekten ein opplever når ein bruker cannabis. Desse stoffa er feittløyselege og kan vere i kroppen i lang tid avhengig av kor ofte ein brukar. Enkeltinntak av THC vil vanlegvis kunne påvisast i opptil ei veke, men ved langvarige store inntak kan det av og til sporast i meir enn éin månad. ',
    cta: 'Klikk på dei ulike molekyla for å lese meir',
    thc: {
      title: 'THC',
      text: 'THC (tetrahydrocannabinol) er eit psykoaktivt stoff som gir rusopplevinga. THC forandrar måten hjernen fungerer på når ein er rusa, og kan føre til at ein endrar humør, medvit, åtferd eller får ei anna røyndomsoppfatning. Ved inntak av cannabis med høgt THC-innhald aukar risikoen for helseskadelege konsekvensar, som t.d. akutt psykose.',
    },
    cbd: {
      title: 'CBD',
      text: 'CBD (cannabidiol) gir ingen rusverknad, men det kan dempe uønskte effektar av THC som angst, problem med minnet og psykotiske symptom. Det er framleis mykje ein ikkje veit om CBD og kva for verknader det kan ha, men det blir forska mykje på dette. Alle CBD-produkt inneheld varierande mengde av THC, og er difor regulerte som narkotika i Noreg.',
    },
  },
  health: {
    headline: 'Helseeffektar',
    text: 'Cannabis kan vere skadeleg for hjernen, særleg for ein ung hjerne i utvikling. Normalt er ikkje hjernen ferdig utvikla før du nærmar deg 25 år. Fleire forskarar er samde om at ein hjerne i utvikling ikkje bør utsetjast for cannabis. Bruk av cannabis kan auke risikoen for depresjon og angst, særleg for sårbare personar og om ein byrjar å bruke i ung alder. Bruk av cannabis kan også føre til at ein blir avhengig.',
    cta: 'Les meir om helseeffektane<br>ved å klikke på knappane',
    korttid: {
      title: 'Korttidsverknader',
      text: `
          <p>Sjølv om cannabisbruk kan føre til helseskadar, vil dei fleste som røykjer hasj eller marihuana ikkje få psykiske problem.</p>
          <p>Det er stor forskjell i korleis ein reagerer på cannabis. Nokon opplever å bli kvalme, engstelege, får hjarteklapp og panikkangst eller blir litt lei seg, medan andre vil berre kjenne seg avslappa, lett, lattermild eller litt svolten. Dette kan også variere frå gong til gong, og går vanlegvis over etter kvart som rusen forsvinn ut av kroppen.</p>
          <p>Bruk av cannabis vil også påverke kognitive funksjonar som merksemd og reaksjonsevne, og det er derfor ein mellom anna ikkje skal køyre bil når ein er påverka. Brukt saman med andre narkotiske stoff og alkohol kan verknaden forsterkast og gi uføreseielege reaksjonar.</p>
        `,
    },
    langtid: {
      title: 'Langtidsverknader',
      text: `
          <p>Jamleg bruk av cannabis over lang tid kan ha meir langsiktige verknader. Ein av verknadene er nedsett kognitiv funksjon, som redusert merksemd, dårlegare minne og evne til å løyse oppgåver.</p>
          <p>Ettersom unge hjernar ikkje er ferdig utvikla kan slike skadar bli permanente dersom ein bruker mykje cannabis i ung alder. Langtidsbruk kan òg føre til psykiske problem som angst og depresjon, og psykosesymptom som hallusinasjonar og vrangførestillingar. Dette gjeld særleg for sårbare personar. I verste fall kan cannabisbruk over lengre tid føre til langvarige psykosar som schizofreni.</p>
        `,
    },
    avhengighet: {
      title: 'Å bli avhengig',
      text: `
          <p>Om ein startar å røykje cannabis i ung alder og brukar mykje, er sjansen større for å bli avhengig. Røykjer du mykje får kroppen din høg toleranse, og ein vil etter kvart trenge meir cannabis for å bli rusa.</p>
          <p>Å vere avhengig vil seie at når du prøver å slutte så kan du oppleve ulike former for abstinensar. Rastløyse, søvnproblem, angst og konsentrasjonsvanskar er vanlege symptom. Er ein avhengig, bruker ein ikkje nødvendigvis cannabis for rusen si skuld, men for å sleppe abstinensar og kjenne seg "frisk".</p>
          <p>Den andre sida av å vere avhengig er at det kan gå ut over det som er viktig for deg i livet, og at du vel cannabis framføre andre aktivitetar. I dag er det fleire som søkjer seg til behandling for cannabisavhengigheit enn tidlegare.</p>
        `,
    },
  },
  smuggling: {
    headline: 'Kvar kjem cannabisen frå?',
    text1:
      'Opphavleg blei planten dyrka i varmare strøk, særleg i Asia, Nord-Afrika og Mellom-Amerika, og der blir det framleis dyrka mykje. Hasjen som blir beslaglagd i Noreg, kjem stort sett frå Marokko og blir smugla hit via Spania og Nederland, men ein stadig større del av den cannabisen som blir brukt i Europa er også dyrka her. Også i Noreg er det avdekt fleire cannabisplantasjar.',
    text2:
      'Dyrking av cannabis kan ha store negative miljø- og samfunnsmessige konsekvensar. Det kan hindre folks tilgang på vatn og bidra til utslepp av miljøskadelege kjemikaliar. Den illegale cannabishandelen er ein av dei viktigaste måtane organiserte kriminelle og terrororganisasjonar finansierer andre ulovlege aktivitetar, som til dømes menneskehandel og kjøp av andre ulovlege stoff og våpen. Den illegale marknaden for cannabisprodukt er den største narkotikamarknaden i Europa. Inntekter frå sal av cannabisprodukt utgjer ei stor inntektskjelde for organiserte kriminelle nettverk i Noreg og Europa.',
  },
  smugglingGame: {
    text: 'Klikk på knappen nedanfor for å styre flyet på vegen mot Noreg. Prøv å treffe dei blå ringane. Trykk på høgre eller venstre side av skjermen for å styre.',
    cta: 'Spel',
    game: {
      text1: 'Hasjen som blir beslaglagd i Noreg kjem stort sett frå Marokko.',
      text2: 'Han blir smugla hit via Spania og Nederland.',
      text3:
        'Ein stadig større del av den cannabisen som blir brukt i Europa er òg dyrka her.',
      text4: 'Også i Noreg er det avdekt fleire cannabisplantasjar.',
    },
  },
  sale: {
    headline: 'Eit sterkare rusmiddel enn tidlegare',
    text: 'Hasj og marihuana har blitt eit mykje sterkare rusmiddel enn tidlegare fordi innhaldet av THC har auka. Gjennomsnittleg innhald av THC i hasj har auka frå rundt 10 % for ti år sidan, til rundt 30 % i dag, men det er registrert THC-innhald heilt opp til 45 %. Auka innhald av THC gir auka risiko for akutte og langsiktige helseskadar. Klarar du å sjå styrkegraden på dei ulike jointane?',
    cta: 'Klikk på ein joint og sjå svaret.',
    joints:
      'Sjølv om snittet i Noreg allereie er høgt på 30 %, har det blitt målt så høgt som 45 %.',
  },
  statistics: {
    headline: 'Kor mange bruker cannabis i Noreg?',
    text: 'Mange trur kanskje at alle har prøvd cannabis, og i nokre miljø er det nok meir vanleg enn i andre. Undersøkingar blant ungdom viser at 90 % ikkje har brukt cannabis. Ungdata-undersøkinga (2024) viser at på ungdomstrinnet har 5 % av gutane og 4 % av jentene brukt cannabis i løpet av det siste året. På vidaregåande er det fleire som seier at dei har brukt; 19 % av gutane og 14 % av jentene svarer at dei har brukt i løpet av siste året. For Oslo (2023) er tala høgare enn elles i landet og på VG3 i Oslo har 38 % av gutane og 25 % av jentene brukt cannabis. Ifølgje ei europeisk skuleundersøking (ESPAD) er norske ungdommars bruk av cannabis blant det lågaste i Europa.',
    numbers: {
      stat1: '90 % av ungdom har ikkje brukt',
      stat2: '19 % av gutar på VGs har brukt',
      stat3: '14 % av jenter på VGs har brukt',
    },
  },
  prohibited: {
    headline: 'Bruk av cannabis er forbode',
    text1:
      'Alle produkt ein kan lage av cannabisplanten er regulerte som narkotika og er i dag forbodne. Også kjemisk framstilt cannabis, syntetiske cannabinoider, blir karakteriserte som narkotika og er forbode. Narkotikaforskrifta slår fast at cannabis er narkotika, medan det i legemiddellova er bestemd at tilverking, innkjøp, omsetning, innførsel, utførsel, oppbevaring, innehaving og bruk av narkotika er forboden. Nokon medisinske cannabisprodukt kan legar tilrå til pasientar som kan ha nytte av dei. Dette er strengt regulert.',
    text2:
      'Brot på narkotikalovgivinga er straffbart, men den som treng helsehjelp på grunn av rusmiddelbruk, skal få det. Stortinget har også vedtatt at politiet ikkje skal straffe rusmiddelbruk av personar som hjelper eller tilkallar nødetatane i akutte situasjonar, som ved overdosar. Ungdom som blir tatt for bruk av cannabis vil ofte få ein alternativ reaksjon frå politiet. Ungdomen kan få ei påtaleunnlating, som betyr at det ikkje blir sett i gang ei straffesak. Politiet kan gje påtaleunnlatinga med eigne vilkår, for eksempel at ein må møte opp ved rådgivande eining i kommunen. Alle kommunar skal ha ei rådgivande eining for russaker. Denne eininga har ansvar for å møte personar når det er sett som vilkår for ein strafferettsleg reaksjon.',
    text3:
      'Fleire parti på Stortinget ønsker å overføre ansvaret for reaksjonane i samfunnet på bruk og innehaving av ulovlege rusmiddel til eige bruk frå justissektoren til helsetenesta. Dei ønsker på den måten å sikre betre hjelp og oppfølging.',
  },
  links: {
    headline: 'Lurer du framleis på noko?',
    text: 'Ønskjer du å vite endå meir om cannabis og korleis det kan påverke hjernen og kroppen din, eller treng du berre nokon å prate med? Her gir vi deg nokre nyttige lenkjer du kan bruke.',
    readmore: 'Les meir',
    ung: 'Offentleg og kvalitetssikra informasjon for ungdom. Også informasjon om cannabis og andre rusmiddel. Du kan stille spørsmål og får svar på det du lurar på.',
    rustelefonen:
      'Landsdekkjande informasjonsteneste om rusmiddelproblematikk. Har mykje informasjon om cannabis. Du kan ta anonym kontakt via telefon, chat eller spørjeskjema.',
    mentalhelse:
      'Ein interesseorganisasjon for ungdom som jobbar med førebygging av psykiske plager. Dersom du treng nokon å snakke med, kan du ta kontakt på chat-tenesta deira.',
    helsenorge:
      'Ein offentleg nettstad for helseinformasjon til alle. Har òg informasjon om cannabis.',
  },
  about: {
    headline: 'Om kampanjen',
    text: '<p>Når dei unge i dag har tilgang på uendelege mengder informasjon på internett, frå kjelder som ikkje alltid er like pålitelege, stiller det høgare krav til Helsedirektoratet som fag- og myndigheitsorgan. Det finst mange mytar om cannabis – som både ufarliggjer stoffet, og som gjer det farligare enn det eigentleg kan vere. Weedensenteret skal derfor fungere som eit digitalt kunnskapssenter for cannabis, med balansert informasjon som skal skape forståing hos ei yngre målgruppe om at cannabis kan innebere ein risiko i ung alder. I kampanjen har vi fokus på å få fram nyansert og faktabasert kunnskap, og ikkje «fryktbasert» informasjon, slik at kampanjen kan gjere ungdom medvitne på når og korleis cannabis kan vere eit problem – både for dei sjølve og vennene rundt dei. For berre gjennom dialog skapar vi meir tillit og openheit.</p>',
  },
  cookies: {
    short:
      'Denne nettsida nyttar <a href="https://www.helsedirektoratet.no/om-oss/om-nettstedet/besoksstatistikk-og-informasjonskapsler" target="_blank" rel="noopener">informasjonskapslar</a> (cookies).',
    long: 'Weedensenteret er en kampanje som skal informere og bevisstgjøre på når og hvordan rusmidler kan bli et problem. Vi minner om at alle produkter av cannabisplanten er regulert som narkotika og er forbudt å bruke, lage, kjøpe, selge eller oppbevare.',
  },
  ui: {
    close: 'Lukk',
    about: 'Om',
    videos: 'Sjå filmar',
  },
  contentmenu: {
    forsiden: 'Framsida',
    planten: 'Planten',
    produkter: 'Produkt',
    kjemi: 'Kjemi',
    helse: 'Helsepåverknader',
    smugling: 'Smugling',
    salg: 'Sal',
    statistikk: 'Statistikk',
    lovverk: 'Lovverk',
    lenker: 'Lenkjer',
  },
};
