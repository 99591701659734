import gsap from 'gsap';
import track from './tracking';

const VIDEOS = [
  {
    id: 'R_Sl3O0awqM',
    title: 'Episode 1: Hva er Cannabis?',
  },
  {
    id: '8zlwM2TFnjA',
    title: 'Episode 2: Har Cannabis blitt sterkere?',
  },
  {
    id: 'GOP6tA9ODh4',
    title: 'Episode 3: Cannabis og psykiske problemer',
  },
  {
    id: 'KvSaMSjUPtk',
    title: 'Episode 4 del 1: Cannabis og hjernen',
  },
  {
    id: 'nFfL4JKjr8c',
    title: 'Episode 4 del 2: Cannabis og skoleprestasjoner',
  },
  {
    id: 'tlCL3UlQGR8',
    title: 'Episode 5: Cannabis og alkohol',
  },
  {
    id: 'SMP0nO_eTGk',
    title: 'Episode 6: Cannabis og avhengighet',
  },
];

class Videos {
  constructor() {
    // video drawer
    const videoDrawer = document.querySelector('#videoDrawer');
    const videoClose = document.querySelector('#videoClose');

    document.querySelector('#videos').addEventListener('click', (evt) => {
      evt.preventDefault();
      videoDrawer.classList.add('show');
      gsap.from(videoDrawer, {
        duration: 0.6,
        x: '100%',
        opacity: 0,
        ease: 'power4.out',
      });
      track.event('se_filmer');
    });

    videoClose.addEventListener('click', (evt) => {
      gsap.to(videoDrawer, {
        duration: 0.6,
        x: '100%',
        opacity: 0,
        ease: 'power2.out',
        clearProps: 'all',
        onComplete: () => {
          videoDrawer.classList.remove('show');
        },
      });
      track.event('lukk_filmer');
    });

    this.insertVideos(videoDrawer);
  }

  insertVideos(container) {
    for (let video of VIDEOS) {
      const el = this.createElement(video);
      container.append(el);
    }
  }

  createElement(video) {
    const el = document.createElement('div');
    const player = document.createElement('div');
    const h3 = document.createElement('h3');
    const iframe = this.createIframe(video.id);

    h3.textContent = video.title;
    player.classList.add('player');
    el.classList.add('video');
    el.append(player);
    el.append(h3);

    player.innerHTML = iframe;

    return el;
  }

  createIframe(code) {
    return `
      <iframe width="100%" height="100%" src="https://www.youtube-nocookie.com/embed/${code}" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>
    `;
  }
}

export default new Videos();
