// sections
import Frontpage from './webgl/sections/frontpage';
import Plant from './webgl/sections/plant';
import Products from './webgl/sections/products';
import Chemistry from './webgl/sections/chemistry';
import Health from './webgl/sections/health';
import Smuggling from './webgl/sections/smuggling';
import SmugglingGame from './webgl/sections/smuggling-game';
import Sale from './webgl/sections/sale';
import Statistics from './webgl/sections/statistics';
import Prohibited from './webgl/sections/prohibited';
import Links from './webgl/sections/links';

export default [
  {
    Constructor: Frontpage,
    name: 'Frontpage',
    el: ['#introText'],
    theme: 'light',
    fog: { near: 15, far: 36 },
  },
  {
    Constructor: Plant,
    name: 'Plant',
    el: ['#plant', '#plantHotspots'],
    theme: 'light',
    fog: false,
  },
  {
    Constructor: Chemistry,
    name: 'Chemistry',
    el: ['#chemistry', '#chemistryHotspots'],
    theme: 'dark',
    fog: false,
  },
  {
    Constructor: Products,
    name: 'Products',
    el: ['#products', '#productHotspots'],
    theme: 'light',
    fog: false,
  },
  {
    Constructor: Health,
    name: 'Health',
    el: ['#health', '#healthHotspots'],
    theme: 'dark',
    fog: false,
  },
  {
    Constructor: Sale,
    name: 'Sale',
    el: ['#sale'],
    theme: 'light',
    fog: false,
  },
  {
    Constructor: Statistics,
    name: 'Statistics',
    el: ['#statistics'],
    theme: 'light',
    fog: false,
  },
  {
    Constructor: Smuggling,
    name: 'Smuggling',
    el: ['#smuggling'],
    theme: 'light',
    fog: false,
  },
  // {
  //   Constructor: SmugglingGame,
  //   name: 'Smuggling Game',
  //   el: ['#smugglingGame'],
  //   theme: 'light',
  //   fog: false,
  // },
  {
    Constructor: Prohibited,
    name: 'Prohibited',
    el: ['#prohibited'],
    theme: 'light',
    fog: false,
  },
  {
    Constructor: Links,
    name: 'Links',
    el: ['#links'],
    theme: 'grey',
    fog: false,
  },
];
