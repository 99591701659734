import gsap from 'gsap';
import track from './tracking';

class About {
  constructor() {
    this.el = document.querySelector('#aboutView');

    const closeBtn = this.el.querySelector('#aboutClose');
    closeBtn.addEventListener('click', () => {
      this.hide();
    });

    const aboutBtn = document.querySelector('#about');
    aboutBtn.addEventListener('click', () => {
      this.show();
      track.event('om_kampanjen');
    });
  }

  hide() {
    gsap.to(this.el, {
      opacity: 0,
      scale: 0.95,
      ease: 'power4.out',
      clearProps: 'all',
      onComplete: () => {
        gsap.set(this.el, { display: 'none' });
      },
    });
  }

  show() {
    const h = this.el.querySelector('h2');
    const p = this.el.querySelectorAll('p');
    gsap.set(this.el, { display: 'flex' });
    gsap.from(this.el, {
      duration: 0.6,
      opacity: 0,
      scale: 0.95,
      top: 10,
      ease: 'power4.out',
    });
    gsap.from(h, {
      y: 20,
      opacity: 0,
      duration: 1,
      ease: 'power3.out',
      delay: 0,
    });

    for (let i = 0; i < p.length; i++) {
      gsap.from(p[i], {
        y: 20,
        opacity: 0,
        duration: 1,
        ease: 'power3.out',
        delay: i * 0.05,
      });
    }
  }
}

export default new About();
