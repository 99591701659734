import gsap from 'gsap';

import { Object3D, MeshLambertMaterial } from 'three';
import assets from '../../assetloader';
import pointer from '../pointer';
import { isTouch } from '../../utils/screen';

import BaseInstance from './baseinstance';
import deniedModel from 'gltf/denied.glb';
import leafModel from 'gltf/leaf.glb';

export default class Prohibited extends BaseInstance {
  constructor(opts) {
    super(opts);
  }

  init() {
    this.container = new Object3D();
    this.add(this.container);

    this.addObjects();

    this.inAnim = gsap.timeline({ paused: true });
    this.outAnim = gsap.timeline({ paused: true });

    if (window.innerWidth > 768) {
      this.setupAnimationsDesktop();
    } else {
      this.setupAnimationsMobile();
    }
  }

  onRaf() {
    if (!this.active) return;

    this.rotation.y = -pointer.normEased.x * 0.2;
    this.rotation.x = pointer.normEased.y * 0.2;
  }

  addObjects() {
    this.symbolContainer = new Object3D();

    const denied = assets.get(deniedModel).scene.children[0];
    const leaf = assets.get(leafModel).scene.children[0].clone();

    denied.material = new MeshLambertMaterial({
      color: 'white',
    });

    leaf.material = new MeshLambertMaterial({ color: 0x007e7b });

    denied.scale.set(20, 20, 20);
    denied.position.set(-3.3, 0, 5);
    leaf.rotation.x = Math.PI * 3.5;
    leaf.position.z = 2;
    leaf.position.y = 1;

    this.symbolContainer.add(denied);
    this.symbolContainer.add(leaf);

    this.symbolContainer.scale.set(1.3, 1.3, 1.3);

    this.container.add(this.symbolContainer);
    this.container.position.y = -5;
  }

  setupAnimationsMobile() {
    this.container.position.y = -7;

    this.inAnim.fromTo(
      '#prohibited',
      { y: '100vh' },
      { y: '-50vh', ease: 'none', duration: 1 },
      0
    );
    this.outAnim.to(
      '#prohibited',
      { y: '-200vh', ease: 'none', duration: 1 },
      0
    );
  }

  setupAnimationsDesktop() {
    this.container.position.x = 3;

    this.inAnim.from(
      '#prohibited',
      { y: '100vh', ease: 'none', duration: 1 },
      0.5
    );
    this.outAnim.to(
      '#prohibited',
      { y: '-200vh', ease: 'none', duration: 1 },
      0
    );
  }
}
