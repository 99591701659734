import gsap from 'gsap';

import BaseInstance from './baseinstance';

export default class Links extends BaseInstance {
  constructor(opts) {
    super(opts);
  }

  init() {
    this.setupAnimations();
  }

  setupAnimations() {
    this.inAnim = gsap.timeline({ paused: true });

    if (window.innerWidth > 768) {
      const el = document.getElementById('links');
      el.style.display = 'block';
      const height = el.offsetHeight;
      el.style.display = 'none';

      if (height > window.innerHeight) {
        this.inAnim.fromTo(
          '#links',
          { y: window.innerHeight },
          { y: window.innerHeight - height - 80, ease: 'none', duration: 2 },
          0
        );
      } else {
        this.inAnim.fromTo(
          '#links',
          { y: '100vh' },
          { y: '0', duration: 2, ease: 'none' },
          1
        );
      }
    } else {
      document.getElementById('links').style.display = 'block';
      const blockHeight = document.getElementById('links').offsetHeight;
      document.getElementById('links').style.display = 'none';

      this.inAnim.fromTo(
        '#links',
        { y: window.innerHeight },
        {
          y: Math.min(0, window.innerHeight - blockHeight) - 40,
          duration: 2,
          ease: 'none',
        },
        0.5
      );
    }
  }
}
