import gsap from 'gsap';

const URL_WORD_TO_TRIGGER_SNAP_TEXT = 'snap';

class Cookies {
  constructor() {
    const el = document.querySelector('#cookieView');
    const close = el.querySelector('.cookie-close button');
    const ls = window.localStorage;

    if (ls) {
      const shown = localStorage.getItem('cookiewarning');

      if (!shown) {
        el.style.display = 'block';
        ls.setItem('cookiewarning', '1');
        gsap.from(el, {
          opacity: 0,
          scale: 0.95,
          ease: 'power2.out',
          delay: 2,
          duration: 0.7,
        });
      }
    }

    // check for snapchat in url
    if (document.location.href.indexOf(URL_WORD_TO_TRIGGER_SNAP_TEXT) > -1) {
      el.querySelector('.long').style.display = 'block';
    }

    // close
    close.addEventListener('click', () => {
      gsap.to(el, {
        opacity: 0,
        scale: 0.95,
        ease: 'power2.Out',
        duration: 0.2,
        onComplete: () => {
          el.style.display = 'none';
        },
      });
    });
  }
}

export default new Cookies();
