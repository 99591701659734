class Tracking {
  constructor() {
    this.debug = document.location.href.indexOf('localhost') > -1;
  }

  event(name) {
    if (this.debug) {
      console.info(`Tracking event: ${name}`);
      return;
    }

    if (window.dataLayer) {
      dataLayer.push({ event: name });
    }
  }
}

export default new Tracking();
