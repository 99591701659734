import {
  Object3D,
  TextureLoader,
  CubeTextureLoader,
  PerspectiveCamera,
  MeshBasicMaterial,
  MeshLambertMaterial,
  MeshPhongMaterial,
  Matrix4,
  Color,
  BoxBufferGeometry,
  SphereBufferGeometry,
  RingBufferGeometry,
  Mesh,
  Vector3,
  DoubleSide,
  Euler,
} from 'three';
import gsap from 'gsap';

import BaseInstance from './baseinstance';
import controller from '../../controller';
import assets from '../../assetloader';
import pointer from '../pointer';

import camera from '../camera';

import earth from 'gltf/earth.glb';
import earthTexture from 'textures/earth-texture-2k.jpg';
import planeTexture from 'textures/plane-texture.jpg';

const deg = (deg) => deg * 0.0174532925;
const EARTH_ROT = -1.6;
const PLANE_SPEED = 0.0008;
const GAME_OVER = 1.3;
const RINGS = [
  {
    pos: new Vector3(2.252, 1.26, 0.351),
    rot: new Euler(deg(-73.46), deg(58.77), deg(74.99)),
    hasAnimated: false,
  },
  {
    pos: new Vector3(1.97, 1.7, 0.155),
    rot: new Euler(deg(-85.33), deg(48.78), deg(78.08)),
    hasAnimated: false,
  },
  {
    pos: new Vector3(1.644, 2.01, -0.185),
    rot: new Euler(deg(-92.57), deg(36.02), deg(91.51)),
    hasAnimated: false,
  },
  {
    pos: new Vector3(1.228, 2.297, -0.249),
    rot: new Euler(deg(-92.88), deg(28.46), deg(86.18)),
    hasAnimated: false,
  },
];

export default class SmugglingGame extends BaseInstance {
  constructor(opts) {
    super(opts);
  }

  init() {
    this.container = new Object3D();
    this.add(this.container);

    this.planeRot = 0;
    this.earthRot = EARTH_ROT;

    this.initialScale = window.innerWidth > 768 ? 1.5 : 1;
    this.initialPos = window.innerWidth > 768 ? -4 : -3;

    this.container.position.y = this.initialPos;

    this.container.scale.set(
      this.initialScale,
      this.initialScale,
      this.initialScale
    );

    this.ringPos = new Vector3();
    this.planePos = new Vector3();

    this.prev = null;

    this.texts = document.querySelectorAll('#smugglingGame .texts p');

    this.setupEarth();
    this.createRings();
    this.setupAnimations();

    document.querySelector('#playSmuggling').addEventListener('click', () => {
      this.playSmuggling();
    });
  }

  playSmuggling() {
    gsap.to(this.container.rotation, { x: -0.85 });
    gsap.to(this.container.scale, { x: 2.5, y: 2.5, z: 2.5 });
    gsap.to(this.container.position, { y: -6, delay: 0.5 });

    const intro = document.querySelector('#smugglingGame .game-intro');
    gsap.to(intro, {
      opacity: 0,
      onComplete: () => {
        intro.style.display = 'none';
      },
    });

    controller.scrollPos = this.origin;

    this.isPlaying = true;
    this.prev = null;
  }

  resetGame() {
    this.isPlaying = false;
    // gsap.to(this.container.rotation, { x: -0.85 });
    gsap.to(this.container.scale, {
      x: this.initialScale,
      y: this.initialScale,
      z: this.initialScale,
    });
    gsap.to(this.container.rotation, { x: 0 });
    gsap.to(this.container.position, { y: this.initialPos });

    this.earth.rotation.set(0.3, EARTH_ROT, 0);
    this.earthRot = EARTH_ROT;

    for (let ring of RINGS) {
      ring.obj.scale.set(1, 1, 1);
      ring.hasAnimated = false;
    }

    for (let text of this.texts) {
      text.style.display = 'none';
    }

    const intro = document.querySelector('#smugglingGame .game-intro');
    intro.style.display = 'block';

    gsap.to(intro, { opacity: 1, duration: 1 });
  }

  setupEarth() {
    const earthModel = assets.get(earth).scene.children[0].children[0];

    const earthGeom = new SphereBufferGeometry(2.6, 32, 32);
    const earthMap = assets.get(earthTexture);
    const planeMap = assets.get(planeTexture);

    const earthMat = new MeshLambertMaterial({
      map: earthMap,
      color: 'white',
    });
    this.earth = new Mesh(earthGeom, earthMat);

    this.planeContainer = new Object3D();
    const plane = earthModel.getObjectByName('Cube001');
    this.planeContainer.add(plane);

    const planeMat = new MeshBasicMaterial({
      map: planeMap,
      color: 'white',
    });

    plane.material = planeMat;
    plane.rotation.set(0, 0, Math.PI);
    plane.position.set(-0.04, 0.15, -2.55);
    plane.scale.set(1500, 1500, 1500);

    this.earth.rotation.set(0.3, this.earthRot, 0);

    this.container.add(this.earth, this.planeContainer);

    plane.castShadow = true;
    this.earth.receiveShadow = true;

    // this.planeContainer.rotation.set(-0.6, 0, 0);
    this.planeContainer.position.set(0.04, -0.25, 2.85);

    const sphere = new Mesh(
      new BoxBufferGeometry(0.04, 0.04, 0.04),
      new MeshLambertMaterial({ color: 0x7f6f52 })
    );
    this.planeContainer.add(sphere);

    // sphere.position.copy(this.planeContainer.position);

    this.sphere = sphere;
  }

  createRings() {
    const geometry = new RingBufferGeometry(0.16, 0.18, 32);
    const material = new MeshLambertMaterial({
      color: 0xffffff,
      side: DoubleSide,
    });

    this.rings = [];

    for (let ring of RINGS) {
      const { pos, rot } = ring;
      const mesh = new Mesh(geometry, material);
      mesh.position.copy(pos);
      mesh.rotation.copy(rot);
      ring.obj = mesh;
      this.earth.add(mesh);
    }
  }

  setupAnimations() {
    this.inAnim = gsap.timeline({ paused: true });
    this.inAnim.from(
      '#smugglingGame',
      { y: window.innerHeight, opacity: 0, ease: 'none', duration: 1 },
      0
    );

    this.outAnim = gsap.timeline({ paused: true });
    this.outAnim.to(
      '#smugglingGame',
      { y: -window.innerHeight, opacity: 0, ease: 'none' },
      0
    );
    this.outAnim.to(
      this.container.position,
      { y: 3, z: -5, ease: 'power1.in' },
      0
    );
  }

  animateRing(obj, num) {
    gsap.to(obj.scale, {
      x: 1.3,
      y: 1.3,
      z: 1.3,
      duration: 0.8,
      ease: 'power4.out',
    });
    gsap.to(obj.scale, {
      x: 0,
      y: 0,
      z: 0,
      duration: 1,
      ease: 'power4.inOut',
      delay: 0.8,
    });

    gsap.to(this.sphere.position, {
      duration: 0.8,
      z: -0.3,
      onComplete: () => {
        this.sphere.position.z = 0;
      },
    });

    // show text
    const delay = this.prev ? 0.5 : 0;
    const prev = this.prev;
    if (prev) {
      gsap.to(prev, {
        y: 20,
        opacity: 0,
        clearProps: 'all',
        onComplete: () => {
          prev.style.display = 'none';
        },
      });
    }

    this.texts[num].style.display = 'block';

    gsap.from(this.texts[num], { y: 20, opacity: 0, delay });
    this.prev = this.texts[num];
  }

  onRaf() {
    if (!this.active || !this.isPlaying) return;

    this.earth.rotation.x += PLANE_SPEED;

    if (this.earth.rotation.x > GAME_OVER) {
      this.resetGame();
    }

    let dir = pointer.norm.x > 0 ? 1 : -1;

    if (pointer.isDown) {
      this.earthRot -= 0.005 * dir;
      this.planeRot = dir * 0.5;
    } else {
      this.planeRot = 0;
    }

    this.earth.rotation.y += (this.earthRot - this.earth.rotation.y) * 0.05;

    this.planeContainer.rotation.y +=
      (this.planeRot - this.planeContainer.rotation.y) * 0.05;

    this.planeContainer.getWorldPosition(this.planePos);

    let count = 0;
    for (let ring of RINGS) {
      const { obj, hasAnimated } = ring;
      if (!hasAnimated) {
        ring.obj.getWorldPosition(this.ringPos);
        const dist = this.planePos.distanceTo(this.ringPos);

        if (dist < 0.75) {
          ring.hasAnimated = true;
          this.animateRing(obj, count);
        }
      }
      count++;
    }
  }
}
