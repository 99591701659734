import { RepeatWrapping, MirroredRepeatWrapping } from 'three';

import assets from './assetloader';

// models
import brain from 'gltf/brain.glb';
import bong from 'gltf/bong.glb';
import cbd from 'gltf/cbd.glb';
import thc from 'gltf/thc.glb';
import denied from 'gltf/denied.glb';
import molecule from 'gltf/molecule.glb';
import flask from 'gltf/flask.glb';
import leaf from 'gltf/leaf.glb';
import plant from 'gltf/plant2.glb';
// import tableWithObjects from 'gltf/table-with-objects.glb';
import tableWithObjects from 'gltf/table.glb';
import earth from 'gltf/earth.glb';
import joint from 'gltf/joint.glb';
import weedbag from 'gltf/weedbag.glb';

// textures
import brainLeftTexture from 'textures/brain-left-texture2.jpg';
//import brainRightTexture from 'textures/brain-right-texture.jpg';
import plantTexture from 'textures/plant-texture.jpg';
import plantAlpha from 'textures/plant-alpha.png';
import plantStemTexture from 'textures/stem-bud-texture.jpg';
import tableTexture from 'textures/table-bake.jpg';
import kolbeTexture from 'textures/kolbe-ext-texture.jpg';
import brownieTex from 'textures/brownie-tex.jpg';
import dryppeflaskeParticlesCombined from 'textures/dryppflaske-particles-combined.jpg';
import bongTex from 'textures/bong-tex.jpg';
import weedBowlTex from 'textures/weed-bowl-tex.jpg';
// import earthTexture from 'textures/earth-texture.jpg';
import earthTexture from 'textures/earth-texture-2k.jpg';
import planeTexture from 'textures/plane-texture.jpg';
import jointTexture from 'textures/joint-texture.jpg';
// import rgbaNoiseTexture from 'textures/rgba_noise_256.png';
import weedbagTexture from 'textures/weedbag-tex.jpg';

// envmap
import front from 'textures/envmap_front.jpg';
import back from 'textures/envmap_back.jpg';
import left from 'textures/envmap_left.jpg';
import right from 'textures/envmap_right.jpg';
import top from 'textures/envmap_top.jpg';
import bottom from 'textures/envmap_bottom.jpg';

// manifest
const manifest = [
  // models
  {
    url: brain,
    type: 'gltf',
    draco: true,
  },
  {
    url: denied,
    type: 'gltf',
    draco: true,
  },
  {
    url: bong,
    type: 'gltf',
    draco: true,
  },
  {
    url: cbd,
    type: 'gltf',
    draco: true,
  },
  {
    url: thc,
    type: 'gltf',
    draco: true,
  },
  {
    url: molecule,
    type: 'gltf',
    draco: true,
  },
  {
    url: flask,
    type: 'gltf',
    draco: true,
  },
  {
    url: leaf,
    type: 'gltf',
    draco: true,
  },
  {
    url: plant,
    type: 'gltf',
    draco: true,
  },
  {
    url: tableWithObjects,
    type: 'gltf',
    draco: true,
  },
  {
    url: earth,
    type: 'gltf',
    draco: true,
  },
  {
    url: joint,
    draco: true,
    type: 'gltf',
  },
  {
    url: weedbag,
    draco: true,
    type: 'gltf',
  },
  // textures
  // {
  //   url: brainRightTexture,
  //   type: 'texture',
  // },
  {
    url: brainLeftTexture,
    type: 'texture',
  },
  {
    url: plantTexture,
    type: 'texture',
  },
  {
    url: plantAlpha,
    type: 'texture',
  },
  {
    url: plantStemTexture,
    type: 'texture',
  },
  {
    url: tableTexture,
    type: 'texture',
  },
  {
    url: kolbeTexture,
    type: 'texture',
  },
  {
    url: brownieTex,
    type: 'texture',
  },
  {
    url: dryppeflaskeParticlesCombined,
    type: 'texture',
  },
  {
    url: bongTex,
    type: 'texture',
  },
  {
    url: earthTexture,
    type: 'texture',
  },
  {
    url: planeTexture,
    type: 'texture',
  },
  {
    url: jointTexture,
    type: 'texture',
  },
  {
    url: weedBowlTex,
    type: 'texture',
  },
  {
    url: weedbagTexture,
    type: 'texture',
  },
  // {
  //   url: rgbaNoiseTexture,
  //   type: 'texture',
  //   settings: {
  //     wrapS: MirroredRepeatWrapping,
  //     wrapT: MirroredRepeatWrapping,
  //   },
  // },
  // images
  {
    urls: [front, back, left, right, top, bottom],
    id: 'envmap',
    type: 'cubetexture',
  },
];

export function loadAll(renderer, onProgress, onComplete) {
  assets.setRenderer(renderer);

  manifest.forEach((item) => {
    assets.queue(item);
  });

  assets.progress(onProgress);
  assets.completed(onComplete);
  assets.load();
}
