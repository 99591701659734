export const en = {
  intro: {
    headline: 'Weeden&shy;senteret',
    text: 'What is cannabis and what does it do to your brain? Scroll down to find out.',
  },
  plant: {
    headline: 'The Cannabis plant',
    text: 'Cannabis is a generic name for all the products that derive from the Cannabis sativa plant. There are many varieties of this plant. It is primarily the buds and the resin from female plants that are used in narcotic cannabis.',
    cta: 'Click the different parts of the plant to find out more',
    toppskudd: {
      title: 'Bud',
      text: 'The bud is the flowering part of the female plant. On the cannabis plant, there are tiny glandular hairs filled with resin, and the glandular hairs on the bud contain the highest concentration of THC, the ingredient in cannabis that produces a high. The bud can be dried to make marijuana. The resin from the bud is used to make hashish.',
    },
    stilk: {
      title: 'Stem',
      text: 'The stem bears the leaves and the flowers and contains very little THC.',
    },
    blad: {
      title: 'Leaf',
      text: 'The leaves are universally familiar as a symbol for cannabis, but often they actually contain very little THC.',
    },
  },
  products: {
    headline: 'There are many cannabis products',
    text: 'Cannabis is a collective term for all the products derived from the cannabis plant. There are also chemically produced substances that have cannabis-like effects. It is mainly the flower buds from the female plant that are used for marijuana, but several parts of the plant also contain active substances that go into making hashish and various oils. All of these products are regulated as narcotics in Norway and it is currently prohibited to use, produce, buy, sell or possess them.',
    cta: 'Click on the different products<br>to find out more',
    hasj: {
      title: 'Hashish',
      text: 'Hashish, or hash, is a resin, a viscous liquid extracted from the plant, which is pressed together with other cannabis plant residues to make a brown lump. A small lump of hashish is often crumbled and mixed with tobacco before being smoked.',
    },
    cannabisolje: {
      title: 'Cannabis oil',
      text: 'Cannabis oil is plant oil extracted from the cannabis plant by means of a solvent. The oil contains a lot of THC and very little CBD. It can be smoked, added to food and drink, or inhaled as vapour.',
    },
    mat: {
      title: 'Food and drink',
      text: 'Cannabis can also be put into drinks and food (cannabis edibles), such as brownies and sweets. Ingesting cannabis rather than smoking it significantly delays the intoxicating effect, usually by one to three hours, and generally makes it last longer. There is a greater risk of overdosing because the high is not apparent until long after ingestion.',
    },
    marihuana: {
      title: 'Marijuana',
      text: 'Marijuana, weed or grass is made from leaves and flowers of the cannabis plant. These are usually dried and then milled or shredded, and smoked either on their own or with tobacco. The THC content of marijuana varies, but it is generally lower than that of hashish.',
    },
    cbdolje: {
      title: 'CBD oil',
      text: 'CBD oil is an oil extracted from the cannabis plant. Unlike cannabis oil, it mostly contains only CBD and not THC, and consequently should not cause intoxication, but this may vary. All extracts of the cannabis plant, including CBD products, are classified as narcotics in Norway because they contain varying amounts of THC.',
    },
    syntetisk: {
      title: 'Synthetic cannabinoids',
      text: 'Synthetic cannabis, or spice, is a large group of narcotics that are chemically produced and have cannabis-like effects. Most of these substances have much stronger effects than natural cannabis products such as hashish and marijuana, and consuming them entails a higher risk of psychosis and complications. Known adverse effects are high blood pressure, elevated pulse, chest pain, myocardial infarction, loss of consciousness, convulsions, agitation, anxiety, panic attacks, acute psychoses and the exacerbation of previously stable mental illness.',
    },
  },
  chemistry: {
    headline: 'Cannabis has many chemical components',
    text: 'The cannabis plant contains many different chemical substances. Those that act on the brain are called cannabinoids. The most well-known cannabinoids are THC and CBD. It is these two that determine a lot of the perceived effect of cannabis use. These substances are fat soluble and can remain in the body for a long time depending on the frequency of consumption. A single intake of THC will usually be detectable for up to a week, while prolonged large intakes can rarely be detected after more than one month.',
    cta: 'Click the different molecules to find out more',
    thc: {
      title: 'THC',
      text: 'THC (tetrahydrocannabinol) is a psychoactive substance that causes intoxication. THC alters the functioning of the brain when the user is high, and brings about changes in mood, consciousness, behaviour and the perception of reality. Consuming cannabis with a high THC content increases the risk of harmful consequences, such as acute psychosis.',
    },
    cbd: {
      title: 'CBD',
      text: 'CBD (cannabidiol) has no intoxicating effect, but it can mitigate undesirable effects of THC, such as anxiety, memory problems, and psychotic symptoms. There is still a lot we do not know about CBD and its effects, but this is an active field of research. All CBD products contain varying amounts of THC, and are consequently regulated as narcotics in Norway.',
    },
  },
  health: {
    headline: 'Impact on health',
    text: 'Cannabis can be harmful to the brain, especially a developing young brain. Normally, your brain has not fully developed until you are nearly 25 years old. Scientists agree that a developing brain should not be exposed to cannabis. Cannabis use may increase the risk of depression and anxiety, especially in vulnerable people and those who start using at a young age. Cannabis use can also become addictive.',
    cta: 'Find out more about the health effects<br>by clicking the buttons',
    korttid: {
      title: 'Short-term effects',
      text: `
          <p>Most people who smoke hashish or marijuana will not get mental health problems.</p>
          <p>Reactions to cannabis vary significantly. Some people experience nausea, anxiety, heart palpitations and panic attacks or feel sad, while others will just feel relaxed, laid-back, merry or a little hungry. This can also vary over time, and usually dissipates as the intoxication disappears from the body.</p>
          <p>Cannabis use will also affect cognitive functions such as concentration and reaction time, which is one of the reasons you should not drive a car under the influence. Used in combination with other drugs and alcohol, the effect can be amplified and cause unpredictable reactions.</p>
        `,
    },
    langtid: {
      title: 'Long-term effects',
      text: `
          <p>Regular use of cannabis over an extended period of time may have long-term effects. One of the effects is impaired cognitive function, including a worsening of concentration, memory and task-solving abilities.</p>
          <p>Because young brains are not fully developed, such damage can become permanent if you use a lot of cannabis at a young age. Long-term use can also lead to psychological problems such as anxiety and depression, and symptoms of psychosis such as hallucinations and delusions. This is especially true for vulnerable people. In the worst case, long-term cannabis use can lead to protracted psychoses such as schizophrenia.</p>
        `,
    },
    avhengighet: {
      title: 'Addiction',
      text: `
          <p>If you start smoking cannabis at a young age and are a heavy user, you are more likely to get addicted. If you smoke a lot, your body will develop a greater tolerance, and you will eventually need more cannabis to get high.</p>
          <p>Addiction means that when you try to quit, you may experience various forms of withdrawal. Restlessness, sleep problems, anxiety and poor concentration are common symptoms. In the case of addiction, cannabis is not necessarily used to get high, but to avoid withdrawal and feel "healthy".</p>
          <p>Another aspect of addiction is that it can override the things in life that are important to you and make you choose cannabis over other activities. Today, more people than ever are seeking treatment for cannabis dependence.</p>
        `,
    },
  },
  smuggling: {
    headline: 'Where does cannabis come from?',
    text1:
      'The plant was originally cultivated in warmer regions, especially in Asia, North Africa and Central America, and is still widely cultivated there. The hash seized in Norway comes mostly from Morocco and is smuggled via Spain and the Netherlands, but an increasing proportion of the cannabis used in Europe is also grown here. Several cannabis plantations have also been uncovered in Norway.',
    text2:
      "Cannabis cultivation can have major negative environmental and social impacts. It can obstruct people's access to water and contribute to environmentally harmful chemical pollution. The illicit cannabis trade is one of the main ways for organised crime and terrorist organisations to finance other illegal activities, such as human trafficking and the purchase of other illegal drugs and weapons.",
  },
  smugglingGame: {
    text: 'Click the button below to steer the aircraft on its way to Norway. Try to hit the circles. Tap the right or left side of the screen to steer.',
    cta: 'Play',
    game: {
      text1: 'The hash seized in Norway comes mostly from Morocco.',
      text2: 'It is smuggled via Spain and the Netherlands.',
      text3:
        'An increasing proportion of the cannabis used in Europe is also grown here.',
      text4: 'Several cannabis plantations have also been uncovered in Norway.',
    },
  },
  sale: {
    headline: 'A stronger drug than before',
    text: 'Hashish and marijuana have become much stronger drugs than before, due to an increased THC content. The average THC content of hashish has risen from around 10% a decade ago to around 30% today, but THC content all the way up to 45% has been observed. Increased THC raises the risk of acute and long-term health damage. How strong are these different joints?',
    cta: 'Click each joint to see the answer',
    joints:
      'Although the average in Norway is already high at 30%, it has been measured at as high as 45%.',
  },
  statistics: {
    headline: 'How many people in Norway use cannabis?',
    text: "Many people tend to think that <i>everyone</i> has tried cannabis, and in some circles it may well be more common than in others. Surveys of lower secondary school pupils indicate that 91% have not tried cannabis. The Ungdata youth survey (2020) shows that, in lower secondary school, 5% of boys and 3% of girls had tried cannabis in the last year. In upper secondary school, more responded that they had tried cannabis in the last year: 19% of boys and 11% of girls. For Oslo (2018), the figures are higher than elsewhere in the country, and in the third year of upper secondary school in Oslo, 38% of boys and 23% of girls had tried cannabis. According to a European school survey, Norwegian adolescents' use of cannabis is among the lowest in Europe. The Ungdata data for 2021 show a slight decline in use, but must be seen in the light of the infection control measures in place during the Covid-19 pandemic.",
    numbers: {
      stat1: 'under-16s have <strong>not</strong> tried',
      stat2: 'boys over 16 have tried',
      stat3: 'girls over 16 have tried',
    },
  },

  prohibited: {
    headline: 'The use of cannabis is prohibited',
    text1:
      'In Norway, the Narcotics Regulations state that cannabis is a narcotic, while the Medicines Act stipulates that the manufacture, acquisition, sale, import, export, storage, possession and use of narcotics is prohibited. All products that can be made from the cannabis plant are regulated as narcotics and are currently prohibited. Chemically manufactured cannabis products, synthetic cannabinoids, are also designated as narcotics and are banned. Breach of the Medicines Act is a criminal offence. Young people caught using cannabis will often be subject to a non-judicial response from the police. The young person may receive a prosecution waiver, which means that the police do not charge him or her, but may require urine testing and follow-up by social workers. Some medical cannabis products can be prescribed by a doctor to a small group of patients who may benefit from them. This is strictly regulated.',
    text2:
      "Several parties in the Norwegian Parliament want responsibility for society's reactions to the use and possession of illicit drugs for personal use to be transferred from the justice sector to the health service. By this means, they aim to ensure better help and follow-up for users. Cannabis currently remains strictly prohibited.",
    text3:
      'The Norwegian Parliament has decided that substance use by anyone who assists or calls the emergency services in acute situations, such as overdoses, shall not be prosecuted.',
  },
  links: {
    headline: 'Do you have any questions?',
    text: 'Would you like to discover even more about cannabis and how it can affect your brain and body, or would you just like someone to talk to? Here are some useful links.',
    readmore: 'Find out more',
    ung: 'Public, quality-assured information for young people. Also information about cannabis and other drugs. You can ask questions and get answers.',
    rustelefonen:
      'Nationwide information service covering substance abuse problems. Contains a lot of information about cannabis. You can contact them anonymously by phone, chat or questionnaire.',
    mentalhelse:
      'An interest organisation for young people that works to prevent mental health problems. If you need someone to talk to, you can get in touch on their chat service.',
    helsenorge:
      'A public health information website for everyone. Also contains information about cannabis.',
  },
  about: {
    headline: 'About the campaign',

    text: `<p>Weedensenteret.no is a digital science center for cannabis. Our aim is to bring out nuanced and fact-based knowledge. We hope to make young people aware of when and how drugs can pose a problem - both for themselves and the friends around them.</p>
           <p>The knowledge base for the website is mainly based on <a href=" https://www.universitetsforlaget.no/cannabisboka-1" target="_blank">Cannabisboka (2019)</a>, reports and internationally recognized literature. The cannabis book refers to the latest research on cannabis. In addition, we have obtained information from RUSinfo, the Institute of Public Health and Oslo University Hospital. In choosing the content on the website, we have also relied on insight into the target group and a reference group consisting of representatives from, among other things, school, health and police.</p>`,
  },
  cookies: {
    short:
      'This website use <a href="https://www.helsedirektoratet.no/om-oss/om-nettstedet/besoksstatistikk-og-informasjonskapsler" target="_blank" rel="noopener">cookies</a>.',
    long: 'The Weed Center is a campaign to inform and raise awareness of when and how drugs can become a problem. We remind you that all products of the cannabis plant are regulated as drugs and are prohibited to use, make, buy, sell or store.',
  },
  ui: {
    close: 'Close',
    about: 'About',
    videos: 'Watch videos',
  },
  contentmenu: {
    forsiden: 'Front page',
    planten: 'The plant',
    produkter: 'Products',
    kjemi: 'Chemistry',
    helse: 'Health',
    smugling: 'Smuggling',
    salg: 'Sale',
    statistikk: 'Statistics',
    lovverk: 'Legislation',
    lenker: 'Links',
  },
};
