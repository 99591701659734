import {
  Object3D,
  TextureLoader,
  CubeTextureLoader,
  PerspectiveCamera,
  MeshBasicMaterial,
  MeshLambertMaterial,
  MeshPhongMaterial,
  Matrix4,
  Color,
  BoxBufferGeometry,
  Mesh,
  Vector3,
} from 'three';
import gsap from 'gsap';

import BaseInstance from './baseinstance';
import dispatcher from '../dispatcher';
import assets from '../../assetloader';
import pointer from '../pointer';
import camera from '../camera';
import raycast from '../raycast';

export default class Statistics extends BaseInstance {
  constructor(opts) {
    super(opts);
  }

  init() {
    this.inAnim = gsap.timeline({ paused: true });
    this.outAnim = gsap.timeline({ paused: true });

    if (window.innerWidth > 768) {
      this.inAnim.from(
        '#statistics',
        {
          y: '100vh',
          ease: 'none',
        },
        0
      );

      this.outAnim.to(
        '#statistics',
        {
          y: '-100vh',
          ease: 'none',
        },
        0
      );

      this.outAnim.to(
        '#statistics',
        {
          opacity: 0,
          duration: 0.1,
          ease: 'none',
        },
        0.4
      );
    } else {
      this.inAnim.fromTo(
        '#statistics',
        { y: '100vh' },
        { y: '-30vh', ease: 'none', duration: 1 },
        0.2
      );
      this.outAnim.to(
        '#statistics',
        { y: '-180vh', ease: 'none', duration: 1 },
        0
      );

      this.outAnim.to(
        '#statistics',
        {
          opacity: 0,
          duration: 0.1,
          ease: 'none',
        },
        0.9
      );
    }
  }
}
