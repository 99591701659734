import { Object3D, MeshLambertMaterial, Mesh, Vector3 } from 'three';
import gsap from 'gsap';

import BaseInstance from './baseinstance';
import assets from '../../assetloader';
import pointer from '../pointer';

import Hotspots from '../hotspots';

import cbd from 'gltf/cbd.glb';
import thc from 'gltf/thc.glb';

import moleculeUrl from 'gltf/molecule.glb';

import { isTouch } from '../../utils/screen';
import { randBetween } from '../../utils';

export default class Chemistry extends BaseInstance {
  constructor(opts) {
    super(opts);
  }

  init() {
    this.container = new Object3D();
    this.add(this.container);

    this.addMolecules();
    this.addGenericMolecules();
    this.setupAnimations();

    const hotspotItems = [
      {
        pos: new Vector3(-1.5, 3, 0),
        el: document.querySelector('#hotspotTHC'),
      },
      {
        pos: new Vector3(1, -2, 0),
        el: document.querySelector('#hotspotCBD'),
      },
    ];

    this.hotspots = new Hotspots({
      items: hotspotItems,
      target: this.container,
      debug: false,
      zoomFactor: 12,
    });
  }

  addMolecules() {
    const mat = new MeshLambertMaterial({ color: 0xd4df8e });

    const thcModel = assets.get(thc).scene.children[0];
    const cbdModel = assets.get(cbd).scene.children[0];

    const thcMesh = new Mesh(thcModel.geometry, mat);
    const cbdMesh = new Mesh(cbdModel.geometry, mat);

    thcMesh.position.set(-1, 2, 0);
    cbdMesh.position.set(0.5, -2.5, 0);
    cbdMesh.rotation.y = Math.PI;

    this.thcMesh = thcMesh;
    this.cbdMesh = cbdMesh;

    this.container.add(thcMesh, cbdMesh);
  }

  addGenericMolecules() {
    const mesh = assets.get(moleculeUrl).scene.children[0];

    const molecules = [
      {
        pos: new Vector3(-5, -4, 6),
      },
      {
        pos: new Vector3(5, 4, -2),
      },
      {
        pos: new Vector3(8, -4, -4),
      },
      {
        pos: new Vector3(-5, 4, 7),
      },
      {
        pos: new Vector3(-5, 0, -3),
      },
      {
        pos: new Vector3(8, 0, 6),
      },
      {
        pos: new Vector3(0, -7, -5),
      },
      {
        pos: new Vector3(-8, -1, 4),
      },

      {
        pos: new Vector3(-1, -2, -9),
      },
      {
        pos: new Vector3(-3, -6, -11),
      },
      {
        pos: new Vector3(1, 4, -8),
      },
      {
        pos: new Vector3(1, 6, -10),
      },
    ];
    const mat = new MeshLambertMaterial({ color: 0x047fa4 });

    for (let molecule of molecules) {
      const m = mesh.clone();
      const { pos } = molecule;
      m.position.copy(pos);
      m.scale.set(1.5, 1.5, 1.5);
      m.rotation.set(
        randBetween(0, Math.PI),
        randBetween(0, Math.PI),
        randBetween(0, Math.PI)
      );
      molecule.rx = randBetween(-0.005, 0.005);
      molecule.ry = randBetween(-0.005, 0.005);
      molecule.rz = randBetween(-0.005, 0.005);
      molecule.range = randBetween(-1, 1);
      molecule.speed = randBetween(0.05, 0.2);

      molecule.obj = m;

      m.material = mat;
      this.container.add(m);
    }

    this.molecules = molecules;
  }

  setupAnimations() {
    this.container.position.x = window.innerWidth > 768 ? 2 : 0;

    this.inAnim = gsap.timeline({ paused: true });
    this.outAnim = gsap.timeline({ paused: true });

    this.inAnim.from(
      '#chemistryHotspots',
      { opacity: 0, ease: 'none', duration: 0.3 },
      1.2
    );

    if (window.innerWidth > 768) {
      this.inAnim.from(
        '#chemistry',
        {
          y: '20vh',
          opacity: 0,
          duration: 1.5,
          ease: 'power2.out',
        },
        2
      );
      this.outAnim.to(
        '#chemistry',
        { y: '-20vh', opacity: 0, duration: 1, ease: 'power2.in' },
        0
      );

      this.inAnim.from(
        this.container.position,
        {
          z: 2,
          y: -5,
          duration: 1,
          ease: 'power2.out',
        },
        0
      );

      this.outAnim.to(this.container.position, {
        z: 2,
        y: 5,
        duration: 1,
        ease: 'power2.in',
      });
    } else {
      this.container.position.y = -1;

      this.inAnim.fromTo(
        '#chemistry',
        {
          y: '100vh',
        },
        {
          y: '-100vh',
          duration: 2,
          ease: 'none',
        },
        0
      );

      this.outAnim.to(
        this.container.position,
        {
          y: 8,
          duration: 0.5,
          ease: 'power1.inOut',
        },
        0
      );
    }

    this.outAnim.to(
      '#chemistryHotspots',
      { opacity: 0, ease: 'none', duration: 0.5 },
      0.5
    );
  }

  onRaf({ elapsed }) {
    if (!this.active) return;

    if (!isTouch) {
      this.rotation.y = -pointer.normEased.x * 0.4;
      this.rotation.x = pointer.normEased.y * 0.4;
    }

    this.thcMesh.rotation.y += 0.005;

    this.cbdMesh.rotation.y += 0.005;

    for (let m of this.molecules) {
      const { obj, rx, ry, rz, range, speed, pos } = m;
      const theta = elapsed * speed;

      obj.rotation.x += rx;
      obj.rotation.y += ry;
      obj.rotation.z += rz;

      obj.position.x = pos.x + Math.cos(theta) * range;
      obj.position.y = pos.y + Math.sin(theta) * range;
      obj.position.z = pos.z + Math.sin(theta) * range;
    }

    this.hotspots.update();
  }
}
