import gsap from 'gsap';
import { Object3D, MeshLambertMaterial } from 'three';

import BaseInstance from './baseinstance';
import pointer from '../pointer';
import assets from '../../assetloader';
import leaf from 'gltf/leaf.glb';

export default class Smuggling extends BaseInstance {
  constructor(opts) {
    super(opts);
  }

  init() {
    this.container = new Object3D();
    const leafModel = assets.get(leaf).scene.children[0].clone();
    const mat = new MeshLambertMaterial({ color: '#007e7b' });
    leafModel.material = mat;
    leafModel.rotation.set(-1.2, 0, 0);
    leafModel.scale.set(0.5, 0.5, 0.5);

    this.add(this.container);
    this.container.add(leafModel);
    this.leafModel = leafModel;

    if (window.innerWidth > 768) {
      this.container.position.set(2, -3, 0);
    } else {
      this.container.position.set(0, 3, 0);
    }

    this.setupAnimations();
  }

  setupAnimations() {
    this.inAnim = gsap.timeline({ paused: true });
    this.outAnim = gsap.timeline({ paused: true });

    if (window.innerWidth > 768) {
      this.outAnim.to(this.leafModel.position, { y: 3, ease: 'none' }, 0);

      this.inAnim.from('#smuggling', { y: '80vh', ease: 'none' }, 0);
      this.outAnim.to('#smuggling', { y: '-120vh', ease: 'none' }, 0);
    } else {
      this.inAnim.fromTo(
        '#smuggling',
        { y: '100vh' },
        { y: '-30vh', ease: 'none', duration: 1 },
        0.2
      );
      this.outAnim.to(
        '#smuggling',
        { y: '-160vh', ease: 'none', duration: 1 },
        0
      );
    }
  }

  onRaf() {
    this.container.rotation.y += 0.005;

    if (window.innerWidth > 768) {
      this.container.position.x = 2 + pointer.normEased.x;
      this.container.position.y = -3 + pointer.normEased.y;
    }
  }
}
