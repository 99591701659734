import { Object3D, MeshBasicMaterial, Vector3 } from 'three';
import gsap from 'gsap';

import BaseInstance from './baseinstance';
import assets from '../../assetloader';
import pointer from '../pointer';

import plantModel from 'gltf/plant2.glb';
import plantTexture from 'textures/plant-texture.jpg';
import plantAlpha from 'textures/plant-alpha.png';
import plantStemTexture from 'textures/stem-bud-texture.jpg';

import Hotspots from '../hotspots';

import { isTouch } from '../../utils/screen';

export default class Plant extends BaseInstance {
  constructor(opts) {
    super(opts);
  }

  init() {
    this.container = new Object3D();
    this.add(this.container);

    this.addPlant();
    this.setupAnimations();

    const hotspotItems = [
      {
        pos: new Vector3(0.5, 3.5, 1),
        el: document.querySelector('#hotspotBud'),
      },
      {
        pos: new Vector3(0, 0, 0),
        el: document.querySelector('#hotspotStem'),
      },
      {
        pos: new Vector3(1.0, -2, 1),
        el: document.querySelector('#hotspotLeaf'),
      },
    ];

    this.hotspots = new Hotspots({
      items: hotspotItems,
      target: this.plantContainer,
      debug: false,
      zoomFactor: 10,
    });
  }

  addPlant() {
    this.plantContainer = new Object3D();

    const plantMap = assets.get(plantTexture);
    const plantAlphaMap = assets.get(plantAlpha);
    const plantStemMap = assets.get(plantStemTexture);

    const plant = assets.get(plantModel).scene.children[0];
    const stem = plant.getObjectByName('stem');
    const bud = plant.getObjectByName('bud');
    const leaf = plant.getObjectByName('leaf_combined');

    const budStemMaterial = new MeshBasicMaterial({
      map: plantStemMap,
      color: 'white',
    });

    leaf.material = new MeshBasicMaterial({
      map: plantMap,
      color: 'white',
      alphaMap: plantAlphaMap,
      alphaTest: 0.9,
    });

    stem.material = budStemMaterial;
    bud.material = budStemMaterial;

    plant.scale.set(1300, 1300, 1300);
    plant.position.set(0, -2.5, 0);

    this.plantContainer.add(plant);
    this.plantContainer.scale.set(1.5, 1.5, 1.5);
    this.container.add(this.plantContainer);
    if (window.innerWidth > 768) {
      this.container.position.x = 2;
    } else {
      this.container.position.x = -0.5;
      this.container.position.y = 2;
    }
  }

  setupAnimations() {
    this.inAnim = gsap.timeline({ paused: true });
    this.outAnim = gsap.timeline({ paused: true });

    this.inAnim.from(
      this.plantContainer.rotation,
      {
        y: 0.5,
        duration: 2,
        ease: 'power4.out',
      },
      0
    );

    if (window.innerWidth > 768) {
      this.inAnim.from(
        '#plant',
        { y: '10vh', opacity: 0, duration: 1.5, ease: 'power2.out' },
        1
      );
      this.outAnim.to(
        '#plant',
        { y: '-10vh', opacity: 0, duration: 0.3, ease: 'power2.in' },
        0
      );

      this.inAnim.from(
        '#plantHotspots',
        {
          opacity: 0,
          ease: 'none',
          duration: 0.2,
        },
        1.2
      );
    } else {
      this.inAnim.from(
        '#plantHotspots',
        {
          opacity: 0,
          ease: 'none',
          duration: 0.2,
        },
        0.8
      );

      this.inAnim.fromTo(
        '#plant',
        { y: '100vh' },
        { y: '-100vh', ease: 'none', duration: 2 },
        0
      );

      this.inAnim
        .fromTo('#plant', { opacity: 0 }, { opacity: 1, duration: 0.2 }, 0)
        .to('#plant', { opacity: 0, duration: 0.2 }, 2);
    }

    this.outAnim.to(
      this.plantContainer.position,
      {
        y: 3,
        ease: 'none',
      },
      0
    );

    this.outAnim.to(
      this.plantContainer.rotation,
      {
        y: -Math.PI,
        ease: 'power2.in',
      },
      0
    );

    this.outAnim.to(
      '#plantHotspots',
      {
        opacity: 0,
        ease: 'none',
        duration: 0.05,
      },
      0.05
    );
  }

  onRaf() {
    if (!this.active) return;

    if (!isTouch) {
      this.rotation.y = -pointer.normEased.x * 0.2;
      this.rotation.x = -pointer.normEased.y * 0.2;
    }

    this.hotspots.update();
  }
}
