export const ur = {
  intro: {
    headline: 'Weeden&shy;senteret',
    text: 'کینابیس کیا ہے اور یہ دماغ کو کیسے متاثر کرتی ہے؟ مزید جاننے کے لیے سکرول کر کے نیچے جائیں۔',
  },
  plant: {
    headline: 'کینابیس کے پودے کے متعلق معلومات',
    text: 'کینابیس ان تمام مصنوعات کے لیے استعمال ہونے والا نام ہے جو Cannabis sativa نامی پودے سے بنتی ہیں۔ اس پودے کی بہت سی قسمیں ہیں۔ کینابیس بنانے کے لیے بالعموم مادّہ پودے کی اوپری پتیاں اور پودے کا عرق استعمال ہوتا ہے۔',
    cta: 'مزید تفصیل پڑھنے کے لیے <br>پودے کے مختلف حصوں پر کلک کریں',
    toppskudd: {
      title: 'اوپری پتیاں',
      text: 'اوپری پتیوں سے مراد مادّہ پودے کا پھول ہے۔ کینابیس کے پودے میں بہت سے چھوٹے چھوٹے بالوں کی صورت میں غدود ہوتے ہیں جن میں عرق اکٹھا ہوتا ہے۔ اوپری پتیوں کے غدود میں کینابیس کے اس مؤثر جزو کی مقدار سب سے زیادہ ہوتی ہے جو نشہ دلاتا ہے۔ اس مؤثر جزو کو THC کہا جاتا ہے۔ اوپری پتیوں کو سکھا کر میریوانا بنائی جاتی ہے۔ اوپری پتیوں کے عرق کو حشیش بنانے کے لیے استعمال کیا جاتا ہے۔',
    },
    stilk: {
      title: 'ڈنٹھل',
      text: 'ڈنٹھل پودے اور پھول کو سہارا دیتا ہے اور اس میں بہت کم THC ہوتا ہے۔ ',
    },
    blad: {
      title: 'پتے',
      text: 'اس پودے کے پتے ساری دنیا میں کینابیس کی عالمی علامت کے طور پر معروف ہیں لیکن پتوں میں اکثر بہت کم THC ہوتا ہے۔',
    },
  },
  products: {
    headline: 'کینابیس کی بہت سی مصنوعات موجود ہیں',
    text: 'کینابیس کا لفظ ان تمام مصنوعات کے لیے استعمال کیا جاتا ہے جو کینابیس کے پودے سے بنتی ہیں۔ اس کے ساتھ ساتھ کیمیکل طریقے سے بنائے گئے ایسے مادّے بھی ہیں جو کینابیس جیسے اثرات پیدا کرتے ہیں۔ میریوانا کے لیے بنیادی طور پر مادّہ پودے کی اوپری پتیاں استعمال کی جاتی ہیں لیکن پودے کے کئی دوسرے حصوں میں بھی نشہ دلانے والے مادّے موجود ہوتے ہیں جنہیں حشیش اور مختلف تیلوں کی تیاری کے لیے استعمال کیا جاتا ہے۔ یہ سب مصنوعات قانون میں منشّیات قرار دی گئی ہیں اور فی زمانہ ان کا استعمال، تیاری، خرید، فروخت اور انہیں اپنے پاس رکھنا ممنوع ہے۔',
    cta: 'مزید تفصیل پڑھنے کے لیے<br>مختلف مصنوعات پر کلک کریں',
    hasj: {
      title: 'حشیش',
      text: 'حشیش ایک گاڑھے سیال کی شکل میں پودے کا عرق ہے جسے کینابیس پودے کے دوسرے بچے کھچے حصوں کو نچوڑ کر حاصل کیا جاتا ہے اور بالآخر یہ ایک براؤن ڈھیلا بن جاتا ہے۔ اکثر حشیش کے ایک چھوٹے سے ڈھیلے کو توڑ کر تمباکو کے ساتھ ملا کر سگرٹ کی طرح پیا جاتا ہے۔',
    },
    cannabisolje: {
      title: 'کینابیس آئل',
      text: 'کینابیس آئل ایک گھلانے والے مادّے کی مدد سے کینابیس کے پودے سے نکالا جانے والا تیل ہے۔ اس آئل میں THC بہت زیادہ اور CBD  بہت کم ہوتا ہے۔ اسے سگرٹ میں پیا جا سکتا ہے، کھانے اور پینے کی چیزوں میں ملایا جا سکتا ہے یا اس کے قطرے پیے جا سکتے ہیں۔',
    },
    mat: {
      title: 'کھانے اور پینے کی چیزیں',
      text: 'کینابیس کو پینے اور کھانے کی چیزوں (اشیائے خوردنی) میں بھی ملایا جا سکتا ہے جیسے براؤنیز اور ٹافیوں میں۔ اگر اس طرح کینابیس استعمال کی جائے تو سگرٹ کی طرح پینے کی نسبت ان چیزوں سے نشہ دیر سے آتا ہے، بالعموم ایک سے تین گھنٹے بعد، اور بالعموم یہ نشہ زیادہ دیر برقرار رہتا ہے۔ اس صورت میں بہت زیادہ کینابیس چڑھا لینے کا خطرہ زیادہ ہوتا ہے کیونکہ لوگوں کو یہ چیزیں استعمال کرنے کے بعد کافی دیر تک نشہ محسوس نہیں ہوتا۔',
    },
    marihuana: {
      title: 'میریوانا',
      text: 'میریوانا، ویڈ یا گراس سے مراد کینابیس پودے کے پتے اور پھول ہیں۔ انہیں بالعموم سکھا کر اور پیس کر پسے مصالحہ جات جیسی شکل میں لایا جاتا ہے اور پھر یا تو خالص میریوانا کو یا میریوانا میں تمباکو ملا کر سگرٹ کی طرح پیا جاتا ہے۔ میریوانا میں THC کی مقدار مختلف ہوتی ہے لیکن بالعموم یہ حشیش میں THC کی مقدار سے کم ہوتی ہے۔ ',
    },
    cbdolje: {
      title: 'CBD آئل',
      text: 'CBD آئل کینابیس کے پودے سے نکالا جاتا ہے۔ کینابیس آئل کی نسبت اس آئل میں بالعموم صرفCBD  ہوتا ہے اور THC نہیں ہوتا لہذا اس کی وجہ سے کسی کو نشہ نہیں ہونا چاہیے لیکن یہ مختلف لوگوں کے لیے مختلف ہو سکتا ہے۔ کینابیس کے پودے سے نکالے جانے والے تمام عرقیات، جن میں CBD مصنوعات بھی شامل ہیں، ناروے میں منشیات شمار ہوتے ہیں کیونکہ ان میں THC کی کچھ نہ کچھ مقدار ہوتی ہے۔',
    },
    syntetisk: {
      title: 'سنتھیٹک (مصنوعی) cannabinoider',
      text: 'سنتھیٹک کینابیس یا "سپائیس" منشیات کا ایک بڑا گروہ ہے جو کیمیائی طریقوں سے بنائی جاتی ہیں اور کینابیس جیسے اثرات رکھتی ہیں۔ ان میں سے اکثر مادّوں کا اثر حشیش اور میریوانا جیسی قدرتی کینابیس مصنوعات سے کہیں زیادہ ہوتا ہے اور ان کے استعمال پر سائیکوسس اور پیچیدگیوں کا زیادہ خطرہ ہوتا ہے۔ معلوم مضر اثرات میں ہائی بلڈ پریشر، تیز نبض، سینے میں درد، دل کا دورہ، بیہوشی، اینٹھن کے ساتھ درد، بے چینی، گھبراہٹ (اینگزائٹی)، اچانک شدید خوف کے دورے، شدید سائیکوسس اور پہلے سنبھلی ہوئی نفسیاتی بیماری کا بگڑ جانا شامل ہیں۔',
    },
  },
  chemistry: {
    headline: 'کینابیس میں کئی کیمیکل اجزا ہوتے ہیں',
    text: 'کینابیس کے پودے میں کئی کیمیکل مادّے شامل ہوتے ہیں۔ دماغ پر اثر کرنے والے مادوں کو cannabinoider کہا جاتا ہے۔ معروف ترین cannabinoider کے نام THC اور CBD ہیں۔ یہی دونوں مادّے کینابیس کے استعمال کے بعد محسوس ہونے والے بیشتر اثر کے لیے ذمہ دار ہیں۔ یہ مادّے چکنائی میں حل ہو جاتے ہیں اور جسم میں دیر تک موجود رہتے ہیں جو اس پر منحصر ہے کہ انسان کتنا اکثر کینابیس استعمال کرتا ہے۔ THC کے ایک بار استعمال کے بعد بالعموم ایک ہفتے تک جسم میں اس کی موجودگی ثابت ہو سکتی ہے اور لمبے عرصے تک زیادہ استعمال کی صورت میں کبھی کبھار ایک مہینے سے زیادہ عرصے تک جسم میں موجودگی معلوم کی جا سکتی ہے۔',
    cta: 'مزید تفصیل پڑھنے کے لیے مختلف مالیکیولز پر کلک کریں۔',
    thc: {
      title: 'THC',
      text: 'THC  (tetrahydrocannabinol)ایک سائیکو ایکٹیو مادّہ ہے جو نشہ دلاتا ہے۔ THC کی وجہ سے نشے کی حالت میں انسان کے دماغ کا فعل بدل جاتا ہے اور اس کے نتیجے میں موڈ، ہوش و حواس اور رویّے میں تبدیلی آ سکتی ہے یا حقیقت کی سمجھ مختلف ہو سکتی ہے۔ زیادہTHC  والی کینابیس استعمال کرنے سے صحت کے لیے نقصان دہ نتائج مثلاً شدید سائیکوسس کا خطرہ بڑھ جاتا ہے۔',
    },
    cbd: {
      title: 'CBD',
      text: 'CBD (cannabidiol) کی وجہ سے نشہ نہیں ہوتا لیکن یہ THC کے غیر مطلوبہ اثرات مثلا گھبراہٹ، یادداشت کے مسائل اور سائیکوسس کی علامات کم کر سکتا ہے۔ ابھی بھی CBD اور اس کے اثرات کے متعلق ہمیں کافی علم نہیں ہے لیکن اس پر بہت تحقیق ہو رہی ہے۔CBD  کی تمام مصنوعات میں THC کی کچھ نہ کچھ مقدار ہوتی ہے لہذا اسے ناروے میں نارکوٹکس یعنی منشیات میں شامل قرار دیا گیا ہے۔',
    },
  },
  health: {
    headline: 'صحت پر اثرات',
    text: 'کینابیس دماغ کے لیے نقصان دہ ہے، خاص طور پر ایک کم عمر انسان کے دماغ کے لیے جس کی نشوونما ابھی جاری ہو۔ عام طور پر 25 سال کی عمر تک انسان کے دماغ کی نشوونما مکمل نہیں ہوئی ہوتی۔ محققین متفق ہیں کہ نشوونما کے دوران دماغ کو کینابیس سے واسطہ نہیں پڑنا چاہیے۔ کینابیس کے استعمال سے ڈپریشن اور گھبراہٹ (اینگزائٹی) کا خطرہ بڑھ سکتا ہے، بالخصوص نازک صورتحال رکھنے والوں کے لیے اور ان لوگوں کے لیے جو کم عمری میں اس کا استعمال شروع کر دیتے ہیں۔ کینابیس استعمال کرنے سے اس کی مستقل لت بھی لگ سکتی ہے۔',
    cta: 'بٹنوں کو دبا کر صحت پر اثرات<br>کے متعلق مزید تفصیل پڑھیں',
    korttid: {
      title: 'تھوڑی دیر رہنے والے اثرات',
      text: `
          <p>حشیش یا میریوانا کو سگرٹ کی طرح پینے والے اکثر لوگوں کو نفسیاتی مسائل نہیں ہوں گے۔</p>
          <p>کینابیس کے اثر کے حوالے سے مختلف لوگوں میں بہت فرق ہوتا ہے۔ کچھ لوگوں کو متلی، گھبراہٹ، دل کی دھڑکن میں بے قاعدگی اور اچانک شدید خوف والی گھبراہٹ ہوتی ہے یا وہ تھوڑے سے اداس ہو جاتے ہیں جبکہ کچھ لوگ صرف سکون، ہلکا پھلکا، زیادہ ہنسنے کا رجحان یا شاید تھوڑی سی بھوک محسوس کرتے ہیں۔ استعمال کے مختلف مواقع پر بھی مختلف اثرات ظاہر ہو سکتے ہیں اور بالعموم جسم سے نشہ دور ہونے کے ساتھ ساتھ اثرات ختم ہو جاتے ہیں۔</p>
          <p>کینابیس کے استعمال سے توجہ اور ردّعمل دکھانے کی صلاحیت پر بھی اثر پڑے گا لہذا کینابیس کے نشے میں گاڑی چلانے جیسے کام کرنا منع ہے۔ دوسرے نشہ آور مادّوں اور الکحل کے ساتھ ملا کر استعمال کرنے سے اثر بہت بڑھ جاتا ہے اور غیر متوقع ردّعمل ظاہر ہو سکتے ہیں۔</p>
        `,
    },
    langtid: {
      title: 'لمبا عرصہ رہنے والے اثرات',
      text: `
          <p>لمبا عرصہ باقاعدگی سے کینابیس استعمال کرنے سے زیادہ دیرینہ اثرات ظاہر ہو سکتے ہیں۔ ایک اثر ذہنی صلاحیتوں کا کمزور ہو جانا ہے جیسے توجہ، یادداشت اور مسائل حل کرنے کی صلاحیت میں کمی۔</p>
          <p>چونکہ کم عمری میں دماغ کی نشوونما مکمل نہیں ہوئی ہوتی، کم عمری میں کینابیس کے زیادہ استعمال کی وجہ سے ایسے نقصان دہ اثرات مستقل ہو سکتے ہیں۔ لمبا عرصہ استعمال کی وجہ سے گھبراہٹ اور ڈپریشن اور سائیکوسس کی علامات جیسے نظر یا سماعت کے دھوکے اور غیر حقیقی تصوّرات جیسے نفسیاتی مسائل بھی ہو سکتے ہیں۔ خاص طور پر نازک صورتحال رکھنے والے لوگوں کے سلسلے میں ایسا ہوتا ہے۔ لمبا عرصہ کینابیس استعمال کرنے سے بدترین صورت میں دیرینہ سائیکوسس مثلاً شزوفرینیا ہو سکتا ہے۔</p>
        `,
    },
    avhengighet: {
      title: 'مستقل لت',
      text: `
          <p>جب انسان کم عمری میں کینابیس کا استعمال شروع کر دے اور اسے زیادہ استعمال کرے تو مستقل لت لگنے کا امکان زیادہ ہوتا ہے۔ اگر آپ زیادہ کینابیس لیتے ہوں تو جسم کی برداشت بڑھ جاتی ہے اور وقت کے ساتھ ساتھ نشہ چڑھانے کے لیے زیادہ کینابیس کی ضرورت پڑتی ہے۔</p>
          <p>مستقل لت لگنے کا مطلب یہ ہے کہ جب انسان اسے چھوڑنے کی کوشش کرتا ہے تو نشے کی کمی کی وجہ سے انسان کو مختلف اثرات پیش آتے ہیں۔ بے چینی، نیند کے مسائل، گھبراہٹ اور دھیان برقرار رکھنے میں مشکل اس کی عام ترین علامات ہیں۔ جس انسان کو لت لگ چکی ہو، وہ لازماً نشہ حاصل کرنے کی خاطر ہی کینابیس نہیں لیتا بلکہ نشے کی کمی کی تکلیفوں سے بچنے اور "صحتمند" محسوس کرنے کی خاطر لیتا ہے۔</p>
          <p>لت کا ایک پہلو یہ بھی ہے کہ یہ آپ کی زندگی میں اہم چیزوں کو متاثر کرتی ہے اور آپ کینابیس کے استعمال کو دوسرے کاموں پر ترجیح دیتے ہیں۔ ماضی کی نسبت آج زیادہ لوگ کینابیس کی لت کا علاج کروا رہے ہیں۔</p>
        `,
    },
  },
  smuggling: {
    headline: 'کینابیس کس علاقے کی پیداوار ہے؟',
    text1:
      'ابتدا میں کینابیس کی کاشت گرم علاقوں بالخصوص ایشیا، شمالی افریقہ اور وسطی امریکا میں ہوتی تھی اور ان علاقوں میں اب بھی اس کی بہت کاشت ہوتی ہے۔ ناروے میں پکڑی جانے والی حشیش بالعموم مراکش سے آتی ہے اور سپین اور ہالینڈ کے راستے یہاں سمگل کی جاتی ہے لیکن یورپ میں استعمال ہونے والی حشیش کا بڑھتا ہوا حصہ اب یورپ میں ہی کاشت ہو رہا ہے۔ ناروے میں بھی کئی جگہوں پر کینابیس کی فصلیں دیکھی گئی ہیں۔',
    text2:
      'کینابیس کی کاشت سے ماحول اور معاشرے پر بہت زیادہ منفی اثرات ہو سکتے ہیں۔ یہ لوگوں کے لیے پانی کے حصول میں رکاوٹ بن سکتی ہے اور ماحول کے لیے مضر کیمیکلز کے اخراج کا باعث بنتی ہے۔ منشیات کی غیر قانونی تجارت منظّم جرائم پیشہ اور دہشت گرد گروہوں کی آمدن کا اہم ذریعہ ہے۔ اس سے دوسرے غیر قانونی دھندوں جیسے انسانوں کی تجارت اور دوسرے غیر قانونی مادّوں اور ہتھیاروں کی خرید کے لیے بھی پیسہ حاصل کیا جاتا ہے ۔',
  },
  smugglingGame: {
    text: 'نیچے بٹن پر کلک کر کے ناروے آنے والے ہوائی جہاز کو کنٹرول کریں۔ نیلے دائروں کو چھونے کی کوشش کریں۔ کنٹرول کرنے کے لیے سکرین کے دائیں یا بائیں حصے پر کلک کریں۔',
    cta: 'کھیلیں',
    game: {
      text1: 'ناروے میں پکڑی جانے والی حشیش بالعموم مراکش سے آتی ہے۔',
      text2: 'یہ سپین اور ہالینڈ کے راستے یہاں سمگل کی جاتی ہے۔',
      text3:
        'یورپ میں استعمال ہونے والی حشیش کا بڑھتا ہوا حصہ اب یورپ میں ہی کاشت ہو رہا ہے۔',
      text4: 'ناروے میں بھی کئی جگہوں پر کینابیس کی فصلیں دیکھی گئی ہیں۔',
    },
  },
  sale: {
    headline: 'پہلے سے بڑھ کر زور دار اثر رکھنے والا نشہ آور مادّہ',
    text: 'ماضی کی نسبت اب حشیش اور میریوانا بہت زیادہ نشہ آور ہو چکی ہیں کیونکہ THC کی مقدار بڑھ گئی ہے۔ حشیش میں THC کی اوسط مقدار 10 سال پہلے سے تقریباً 10٪ بڑھ کر آج تقریباً 30٪ ہو چکی ہے لیکن کچھ حشیش میں اس سے بھی بہت زیادہ یعنی 45٪ تک THC رجسٹر کیا گیا ہے۔ THC کی زیادہ مقدار سے صحت کو شدید اور دیرینہ نقصانات کا زیادہ خطرہ ہوتا ہے۔ کیا آپ مختلف جوائنٹس کی شدّت یا اثر دیکھ سکتے ہیں؟',
    cta: 'باری باری جوائنٹس پر کلک کریں اور جواب دیکھیں',
    joints:
      'اگرچہ ناروے میں اوسط پہلے ہی زیادہ یعنی 30٪ ہے، اس سے بھی بہت زیادہ مقدار یعنی 45٪ تک ماپی گئی ہے۔',
  },
  statistics: {
    headline: 'ناروے میں کینابیس استعمال کرنے والوں کی تعداد کیا ہے؟',
    text: 'کئی لوگ سمجھتے ہیں کہ شاید سبھی کینابیس آزما چکے ہیں اور کچھ ماحولوں میں غالباً کینابیس کا استعمال دوسروں کی نسبت زیادہ عام ہے۔ نوجوانوں کے کئی سروے دکھاتے ہیں کہ 91٪ نے کینابیس نہیں آزمائی ہے۔ Ungdata سروے (2020) سے پتہ چلتا ہے کہ پچھلے سال میں ہائی سکول کے 5٪ لڑکوں اور 3٪ لڑکیوں نے کینابیس آزمائی۔ اپر سیکنڈری سکول میں اس سے زیادہ لوگ بتاتے ہیں کہ وہ کینابیس آزما چکے ہیں؛ 19٪ لڑکوں اور 11٪ لڑکیوں نے بتایا کہ وہ پچھلے سال کے دوران اسے آزما چکے ہیں۔ اوسلو (2018) کے اعدادوشمار باقی ملک سے زیادہ ہیں اور اوسلو میں VG3 میں 38٪ لڑکے اور 23٪ لڑکیاں کینابیس آزما چکی ہیں۔ سکولوں کے ایک یورپیئن سروے کے مطابق نارویجن نوجوان یورپ میں سب سے کم کینابیس استعمال کرنے والوں میں شامل ہیں۔ 2021 کے Ungdata کے اعدادوشمار سے استعمال میں تھوڑی سی کمی ظاہر ہوتی ہے لیکن اس سلسلے میں COVID-19کے دوران انفیکشن سے بچاؤ کے اقدامات کو مدنظر رکھنا چاہیے۔',
    numbers: {
      stat1: '91٪ نوجوانوں نے اسے نہیں آزمایا ہے۔',
      stat2: 'VGS کے 19٪ لڑکے اسے آزما چکے ہیں',
      stat3: 'VGS کی 11٪ لڑکیاں اسے آزما چکی ہیں',
    },
  },

  prohibited: {
    headline: 'کینابیس کا استعمال ممنوع ہے',
    text1:
      'منشیات کے ضوابط کینابیس کو منشیات میں شامل قرار دیتے ہیں جبکہ ادویات کے قانون کی رو سے منشیات کی تیاری، حصول، خریدوفروخت، درآمد، برآمد، ذخیرہ، قبضہ اور استعمال ممنوع ہے۔ کینابیس کے پودے سے بننے والی تمام مصنوعات کو قانون منشیات قرار دیتا ہے اور فی زمانہ یہ ممنوع ہیں۔ کیمیکل طریقے سے بنائی گئی کینابیس یعنی سنتھیٹک cannabinoider کو بھی منشیات قرار دیا گيا ہے اور یہ ممنوع ہے۔ ادویات کے قانون کی خلاف ورزی قابل سزا جرم ہے۔ کینابیس کے استعمال پر پکڑے جانے والے نوجوانوں کے لیے پولیس اکثر متبادل ری ایکشن طے کرتی ہے۔ نوجوانوں کو فرد جرم عائد کیے جانے سے چھوٹ مل سکتی ہے یعنی پولیس ان کے خلاف فوجداری مقدمہ نہیں کرے گی بلکہ انہیں پیشاب کے نمونوں کی ٹیسٹنگ اور سوشل ورکرز کی نگرانی جیسے احکام کا پابند کرے گی۔ مریضوں کے ایک چھوٹے سے گروہ کو کینابیس کی بعض طبی مصنوعات سے فائدہ ہو سکتا ہے جنہیں ڈاکٹر یہ نسخے پر دے سکتے ہیں۔ اس سلسلے میں سخت ضوابط کا اطلاق ہوتا ہے۔',
    text2:
      'نارویجن پارلیمنٹ میں کئی پارٹیوں کی خواہش ہے کہ غیر قانونی منشیات کے ذاتی استعمال اور ذاتی استعمال کی خاطر منشیات رکھنے پر معاشرتی ردّعمل کو نظام انصاف کی بجائے نظام صحت کے سپرد کر دیا جائے۔ اس سے ان کا مقصد بہتر مدد اور خبرگیری یقینی بنانا ہے۔ کینابیس آج بھی ممنوع ہے۔',
    text3:
      'نارویجن پارلیمنٹ کا فیصلہ ہے کہ منشیات استعمال کرنے والے جو افراد ایمرجنسی صورتحال مثلاً اوور ڈوز کی صورت میں مدد کریں یا ایمرجنسی اداروں کو بلائیں، انہیں پولیس سزا نہیں دلوائے گی۔',
  },
  links: {
    headline: 'کیا اب بھی آپ کا کوئی سوال ہے؟',
    text: 'کیا آپ کینابیس اور دماغ اور جسم پر اس کے اثرات کے متعلق مزید معلومات لینا چاہتے ہیں یا کیا آپ کو صرف کسی سے بات کرنے کی ضرورت ہے؟ یہاں ہم آپ کے لیے کچھ مفید لنکس دے رہے ہیں۔',
    readmore: 'مزید تفصیل پڑھیں',
    ung: 'حکومت کی طرف سے معیار کی تسلّی کے بعد نوجوانوں کے لیے معلومات۔ کینابیس اور دوسری منشیات کے متعلق معلومات بھی ہیں۔ آپ کے ذہن میں جو بھی سوال ہوں، آپ پوچھ سکتے ہیں اور آپ کو جواب ملے گا۔',
    rustelefonen:
      'منشیات کے مسائل کے متعلق ملک گیر معلوماتی سروس۔ کینابیس کے متعلق بہت سی معلومات دیتی ہے۔ آپ فون، چیٹ یا سوالیہ فارم کے ذریعے اپنا نام بتائے بغیر رابطہ کر سکتے ہیں۔',
    mentalhelse:
      'نوجوانوں کے مفادات کے لیے کام کرنے والی تنظیم جو نفسیاتی بیماریوں کی روک تھام کرتی ہے۔ اگر آپ کو کسی سے بات کرنے کی ضرورت ہو تو آپ ان کی چیٹ سروس پر رابطہ کر سکتے ہیں۔',
    helsenorge:
      'سب لوگوں کو صحت کے متعلق معلومات دینے والی ایک سرکاری ویب سائیٹ۔ یہاں کینابیس کے متعلق معلومات بھی ہیں۔',
  },
  about: {
    headline: 'اس مہم کے متعلق معلومات',

    text: `<pWeedensenteret.no کینابیس کے متعلق ایک ڈیجیٹل معلوماتی مرکز ہے۔ ہمارا مقصد مختلف پہلو دکھانے والی اور حقائق پر مبنی معلومات سامنے لانا ہے۔ ہمیں نوجوانوں میں یہ شعور پیدا کرنے کی امید ہے کہ منشیات کب اور کیسے ایک مسئلہ بن سکتی ہیں – خود ان کے لیے بھی اور ان کے دوستوں کے لیے بھی۔</p>
      <p>اس ویب سائیٹ پر معلومات بنیادی طور پر Cannabisboka  (2019)، رپورٹوں اور بین الاقوامی مسّلم لٹریچر سے لی گئی ہیں۔ اس کے ساتھ ساتھ ہم نے RUSinfo، نارویجن انسٹی ٹیوٹ آف پبلک ہیلتھ اور اوسلو یونیورسٹی ہاسپٹل سے بھی معلومات لی ہیں۔ ویب سائیٹ کے لیے مواد کا انتخاب کرتے ہوئے ہم نے ٹارگٹ گروپ سے اور سکولوں، صحت اور پولیس کے نمائندوں پر مشتمل ایک ریفرنس گروپ سے صورتحال کو سمجھنے کے لیے بھی مدد لی ہے۔</p>`,
  },
  cookies: {
    short:
      'Denne nettsiden benytter <a href="https://www.helsedirektoratet.no/om-oss/om-nettstedet/besoksstatistikk-og-informasjonskapsler" target="_blank" rel="noopener">informasjonskapsler</a> (cookies).',
    long: 'Weedensenteret er en kampanje som skal informere og bevisstgjøre på når og hvordan rusmidler kan bli et problem. Vi minner om at alle produkter av cannabisplanten er regulert som narkotika og er forbudt å bruke, lage, kjøpe, selge eller oppbevare.',
  },
  ui: {
    close: 'بند کریں',
    about: 'کے بارے میں',
    videos: 'ویڈیو دیکھیں',
  },
  contentmenu: {
    forsiden: 'فرنٹ پیج',
    planten: 'پودا',
    produkter: 'مصنوعات',
    kjemi: 'کیمسٹری',
    helse: 'صحت کے اثرات',
    smugling: 'سمگلنگ',
    salg: 'فروخت',
    statistikk: 'شماریات',
    lovverk: 'قانون سازی',
    lenker: 'لنکس',
  },
};
