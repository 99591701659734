import sections from './sections';
import controller from './controller';
import track from './tracking';

class Sidenav {
  constructor() {
    const el = document.querySelector('nav.content-menu');
    this.navElements = el.querySelectorAll('a');

    this.navElements.forEach((el) => {
      el.addEventListener('click', (evt) => {
        evt.preventDefault();
        const index = this.getClickedIndex(this.navElements, el);
        let offset = parseFloat(el.getAttribute('data-scrolloffset'));

        if (isNaN(offset)) offset = 0;
        else offset = window.innerHeight * offset;

        this.toggleActive(index);
        controller.setIndex(index, offset);

        // tracking
        const evtName = `sidemeny_${el.getAttribute('data-id')}`;
        track.event(evtName);
      });
    });
  }

  getClickedIndex(elements, clicked) {
    for (let i = 0; i < elements.length; i++) {
      const el = elements[i];
      if (el === clicked) return i;
    }
    return null;
  }

  toggleActive(activeIndex) {
    if (activeIndex >= 8) activeIndex -= 1;

    this.navElements.forEach((el, index) => {
      if (index !== activeIndex) {
        el.classList.remove('active');
      } else {
        el.classList.add('active');
      }
    });
  }
}

export default new Sidenav();
