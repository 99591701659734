export const ar = {
  intro: {
    headline: 'Weeden&shy;senteret',
    text: 'ما هو القنّب وكيف يؤثر في الدماغ؟ انتقل للأسفل لتعرف المزيد.',
  },
  plant: {
    headline: 'هذا نبات القنّب',
    text: 'نبات القنب هو مصطلح مشترك لكل المنتجات التي مصدرها نبات القنب ساتيفا sativa. وهناك العديد من الأنواع لهذا النبات. الذي يستعمل أساسًا هو البراعم العلوية والراتنج النباتي (المادة الصمغية) للنباتات الأنثوية التي تستعمل في القنب.',
    cta: 'انقر على الأجزاء المختلف للنبات<br>لقراءة المزيد',
    toppskudd: {
      title: 'البراعم العلوية',
      text: 'البراعم العلوية هي جزء الزهرة من النبات الأنثوي. يوجد على نبات القنب شعيرات غددية صغيرة مليئة بالراتنج (المادة الصمغية). في الشعيرات الغددية للبراعم العلوية سنجد التركيز الأكبر للمادة الفعالة في القنب التي تسبب نشوة السُّكر. المادة الفعالة تُسمّى تي إتش سي THC. يمكن تجفيف البراعم العلوية لصنع الماريوانا. يُستعمل الراتنج النباتي (المادة الصمغية) من البراعم العلوية لصنع الحشيش.',
    },
    stilk: {
      title: 'الجذع',
      text: 'يحمل الجذع النبات والزهرة، ويحتوي على القليل جدًا من مادة تي إتش سي THC.',
    },
    blad: {
      title: 'الأوراق',
      text: 'تعرف أوراق النبات في جميع أنحاء العالم كرمز عالمي للقنب، لكنها غالبًا ما تحتوي على القليل جدًا من مادة تي إتش سي THC.',
    },
  },
  products: {
    headline: 'هناك العديد من منتجات القنب',
    text: 'القنب هو مصطلح عام لجميع المنتجات المصنوعة من نبات القنب. إضافة لذلك، هناك مواد مصنعة كيميائيًا لها مفعول شبيه بالقنب. الذي يُستعمل أساسًا هو البراعم العلوية للنباتات الأنثوية التي تستعمل للماريوانا، لكن العديد من أجزاء النبات تحتوي أيضًا على مواد مؤثرة تستعمل لصنع الحشيش والزيوت المختلفة. جميع هذه المنتجات صُنّفت قانونيًا كمخدرات وهي اليوم محظورة الاستعمال أو التصنيع أو الشراء أو البيع أو التخزين.',
    cta: 'انقر على المنتجات المختلفة<br>لقراءة المزيد',
    hasj: {
      title: 'الحشيش',
      text: 'الحشيش هو راتنج (مادة صمغية)، سائل لزج من النبات يتم ضغطه مع بقايا نبات القنب حتى يتحول إلى كتلة بنية اللون. غالبًا ما يتم تفتيت قطعة صغيرة من الحشيش وخلطها مع التبغ قبل تدخينها.',
    },
    cannabisolje: {
      title: 'زيت القنب',
      text: 'زيت القنب هو زيت نباتي مستخرج من نبات القنب عبر استعمال مادة مذيبة. يحتوي الزيت على كمية كبيرة من مادة تي إتش سي THC وقليل جدًا من مادة سي بي دي CBD. قد يُدخّن مثلاً، أو يوضع في الطعام والشراب أو يتناول خالصًا على شكل قطرات.',
    },
    mat: {
      title: 'الأطعمة والمشروبات',
      text: 'وقد يوضع القنب أيضًا في المشروبات والأطعمة (المأكولات)، مثل كعك البرونيز والحلوى. إن تناول المرء القنب بهذه الطريقة، فسيحدث تأثير نشوة السُّكر في وقت متأخر جدًا عن التدخين، وغالبًا بعد ساعة إلى ثلاث ساعات، ويستمر عادة لوقت أطول. هناك مخاطر أكبر من تناول الكثير من القنب لأن المرء لا يشعر بنشوة السُّكر قبل مضي وقت طويل من تناوله.',
    },
    marihuana: {
      title: 'الماريوانا',
      text: 'الماريوانا، سواء العشب الضار أو الأخضر، هي أوراق وأزهار من نبات القنب. وهذه يتم عادة تجفيفها وطحنها إلى قوام توابل، وتُدخّن إما لوحدها أو مع التبغ. يتفاوت محتوى مادة تي إتش سي THC في الماريوانا، لكنه إجمالاً أقل من الحشيش.',
    },
    cbdolje: {
      title: 'زيت سي بي دي CBD',
      text: 'زيت سي بي دي CBD مستخرج من نبات القنب. خلافًا لزيت القنب، يحتوي عمومًا مادة سي بي دي CBD فقط وليس مادة تي إتش سي THC، ولذلك فهو لا يسبب نشوة السُّكر، لكن الأمر قد يتفاوت. جميع المواد المستخرجة من نبات القنب، وكذلك منتجات سي بي دي CBD، مصنفة كمخدرات في النرويج لأنها تحتوي على كميات متنوعة من مادة تي إتش سي THC.',
    },
    syntetisk: {
      title: 'الكانابينويد الصناعي',
      text: 'القنب الصناعي، أو "التوابل"، هو مجموعة كبيرة من العقاقير المخدرة التي تُنتج كيميائيًا ولها تأثيرات شبيهة بالقنب. معظم هذه المواد لها آثار قوية جدًا مقارنة بالمنتجات الطبيعية للقنب كالحشيش والماريوانا، وتزيد من مخاطر الإصابة بالاضطراب العقلي والمضاعفات عند تناولها. الآثار الضارة المعروفة هي ارتفاع ضغط الدم، والنبض السريع وآلام الصدر والنوبات القلبية وفقدان الوعي والتشجنات والأرق والقلق ونوبات الهلع والاضطراب العقلي الحاد وتفاقم الأمراض العقلية المستقرة سابقًا.',
    },
  },
  chemistry: {
    headline: 'يتكون القنب من عدة مكونات كيميائية.',
    text: 'نبات القنب يحتوي على العديد من المواد الكيميائية المختلفة. تلك التي تؤثر في الدماغ نسميها كانابينويد. الأنواع الأكثر شهرة لمادة الكانابينويد هي تي إتش سي THC و سي بي دي CBD. هذان هما اللذان يحددان أجزاء كبيرة من التأثير الحسي عند استعمال القنب. هذه المواد قابلة للذوبان في الدهون وقد تبقى في الجسم لوقت طويل، وذلك يتوقف على عدد المرات التي يستخدمها المرء. تناول مادة تي إتش سي THC لمرة واحدة يمكن عادة اكتشافها في الجسم لمدة قد تصل إلى أسبوع، وعند تناول كمية كبيرة لفترة طويلة نادرًا ما يتم اكتشافها بعد وضي شهر واحد.',
    cta: 'انقر على الجزيئات المختلفة لقراء المزيد',
    thc: {
      title: 'تي إتش سي THC',
      text: 'تي إتش سي THC (تيتراهيدروكانابينول) هي مادة ذات تأثير ذهاني (اضطراب عقلي) تسبب الشعور بنشوة السُّكر. تغير مادة تي إتش سي THC من طريقة عمل الدماغ حين يصاب المرء بنشوة السُّكر، وقد تؤدي إلى تغير مزاج المرء أو وعيه أو سلوكه أو يصبح لديه إدراك مختلف للواقع. عند تناول القنب الذي يحتوي على كمية كبيرة من مادة تي إتش سي THC، تزيد مخاطر العواقب الضارة بالصحة، من مثل الاضطراب العقلي الحاد (akutt psykose).',
    },
    cbd: {
      title: 'سي بي دي CBD',
      text: 'لا تسبب مادة سي بي دي CBD (كانابيديول) تأثيرًا مسكرًا، لكنها قد تقلل من الآثار غير المرغوبة الناتجة عن مادة تي إتش سي THC مثل القلق ومشاكل الذاكرة والأعراض الذهانية (الاضطراب العقلي). لا يزال هناك الكثير لا نعرفه عن مادة سي بي دي CBD والآثار المترتبة عليها، إلا أنه تجري أبحاث كثيرة عليها. جميع منتجات مادة سي بي دي CBD تحتوي على كميات متفاوتة من مادة تي إتش سي THC، ولذلك صُنّفت قانونيًا كمخدرات في النرويج.',
    },
  },
  health: {
    headline: 'الآثار الصحية',
    text: 'قد يسبب القنب ضررًا في الدماغ، خاصة للأدمغة الشابة في طور النمو. عادة لا يكتمل نمو الدماغ قبل اقترابك من بلوغ 25 سنة. يتفق الباحثون على أنه لا ينبغي تعريض الدماغ للقنب وهو في طور النمو. استعمال القنب قد يزيد من مخاطر الاكتئاب والقلق، خاصة لدى الأشخاص الضعفاء، وفي حال بدأ المرء باستعماله في سن مبكرة. قد يؤدي استعمال القنب إلى الإدمان أيضًا.',
    cta: 'اقرأ المزيد حول الآثار الصحية<br>بالنقر على الأزرار',
    korttid: {
      title: 'الآثار القصيرة المدى',
      text: `
          <p>معظم الذين يدخنون الحشيش أو الماريوانا، لن يصابوا بمشاكل نفسية.</p>
          <p>هناك فرق كبير في كيفية تفاعل المرء مع القنب. يشعر البعض بالغثيان والقلق وخفقات القلب ونوبات الهلع أو يشعرون بشيء من الحزن، بينما يشعر الآخرون بالاسترخاء أو الخفة أو الضحك أو القليل من الجوع. وقد يتفاوت ذلك أيضًا من مرة إلى أخرى، ويختفي عادة بعد كل مرة تنتهي نشوة السُّكر من الجسم.</p>
          <p>وقد يؤثر استعمال القنب أيضًا على الانتباه والقدرة على التفاعل، ولذلك لا ينبغي للمرء مثلاً أن يقود السيارة عندما يكون المرء خاضعًا لتأثير القنب. ومن شأن استعماله مع المواد المخدرة الأخرى والكحول أن يزيد التأثير ويسبب ردود فعل غير متوقعة.</p>
        `,
    },
    langtid: {
      title: 'الآثار البعيدة المدى',
      text: `
          <p>الاستعمال المنتظم للقنب لفترة طويلة قد تكون له آثار طويلة المدى. إحدى هذه الآثار هو انخفاض مستوى الوظيفة الإدراكية، وتدني مستوى الانتباه والذاكرة والقدرة على حل المهمات.</p>
          <p>بما أن أدمغة الشباب لم يكتمل نموها بعد، فقد يصبح هذا الضرر دائمًا إذا استعمل القنب كثيرًا في عمر مبكر. الاستعمال لزمن طويل قد يؤدي أيضًا إلى مشاكل نفسية كالقلق والاكتئاب، وأعراض الاضطراب العقلي كالهلوسات والتخيلات. وهذا ينطبق خاصة على الأشخاص الضعفاء. في أسوأ الأحوال، قد يؤدي استعمال مادة القنب لزمن طويل إلى اضطرابات عقلية مزمنة كانفصام الشخصية.</p>
        `,
    },
    avhengighet: {
      title: 'الإدمان',
      text: `
          <p>إذا بدأ المرء بتدخين القنب في عمر مبكر واستعمله كثيرًا، فمن المرجح أن يصبح مدمنًا. إذا كنت تدخن كثيرًا، فإن جسمك يطور قدرة تحمل عالية، وستحتاج في النهاية إلى المزيد من القنب للشعور بنشوة السُّكر.</p>
          <p>الإدمان يعني أنك ستشعر بأشكال مختلفة من أعراض الحالة حين تحاول الإقلاع عن تدخينه. الشعور بعدم الارتياح ومشاكل النوم والقلق وصعوبات التركيز هي أعراض عادية. في حالة الإدمان، فإن المرء لا يستعمل القنب لأجل نشوة السُّكر بالضرورة، بل للتخلص من أعراض الإقلاع والشعور بـ «النشاط».</p>
          <p>الجانب الآخر من الإدمان هو أنه قد يؤثر على الأمور الهامة في الحياة، وأن تهتم بالقنب بدلاً من الأنشطة الأخرى. والذين يبحثون عن العلاج من إدمان القنب هم اليوم أكثر من السابق.</p>
        `,
    },
  },
  smuggling: {
    headline: 'من أين يأتي القنب؟',
    text1:
      'في الأصل، زُرعت النبتة في المناطق الأكثر دفئًا، خاصة في آسيا وشمال أفريقيا وأمريكا اللاتينية، ولا يزال يُزرع الكثير منها. يأتي الحشيش المُصادَر في النرويج غالبًا من المغرب ويتم تهريبه إلى هنا عبر إسبانيا وهولندا، إلا أن جزءًا كبيرًا من القنب المستخدم في أوروبا، يُزرع هنا أيضًا بشكل متزايد. كما تم اكتشاف العديد من مزارع القنب في النرويج.',
    text2:
      'قد تؤدي زراعة القنب إلى عواقب بيئية ومجتمعية كبيرة. من شأن ذلك أن يعيق وصول الناس إلى المياه ويساهم في إطلاق مواد كيميائية ضارة بالبيئة. تشكل تجارة المخدرات غير المشروعة مصدر دخل مهم لعصابات الجريمة المنظمة والمنظمات الإرهابية. وهذا يمول الأنشطة غير القانونية الأخرى مثل الاتجار بالبشر وشراء المخدرات والأسلحة الأخرى غير المشروعة.',
  },
  smugglingGame: {
    text: 'انقر على الزر أدناه لتسيير الطائرة نحو النرويج. حاول أن تصيب الحلقات الزرقاء. اضغط على الجانب الأيمن أو الأيسر من الشاشة للتوجيه.',
    cta: 'لعب',
    game: {
      text1: 'الحشيش الذي يُصادر في النرويج يأتي في الأغلب من المغرب.',
      text2: 'يجري التهريب عبر إسبانيا وهولندا.',
      text3: 'جزء كبير من القنب المستعمل في أوروبا لا يزال يزرع هنا أيضًا.',
      text4: 'وفي النرويج أيضًا تم الكشف عن العديد من مزارع القنب.',
    },
  },
  sale: {
    headline: 'مادة مخدرة أقوى من السابق',
    text: 'الحشيش والماريوانا أصبحت مواد مخدرة أقوى جدًا من السابق بسبب ارتفاع محتوى مادة تي إتش سي THC. لقد ارتفع متوسط محتوى مادة تي إتش سي THC من حوالي 10 % منذ عشر سنوات، إلى 30 % اليوم، لكن محتوى مادة تي إتش سي THC المسجل يصل إلى 45 %. زيادة محتوى مادة تي إتش سي THC تزيد من مخاطر الأضرار الصحية الحادة والمزمنة. هل يمكنك رؤية مستوى القوة في سجائر المخدرات المختلفة؟',
    cta: 'انقر على سيجارة المخدر لترى الجواب',
    joints:
      'بالرغم من أن المتوسط في النرويج مرتفع بالفعل بقدر 30 %، فقد تم قياسه بارتفاع قدره 45 %.',
  },
  statistics: {
    headline: 'كم عدد الذين يستعملون القنب في النرويج؟',
    text: 'يظن الكثيرون أن الجميع جربوا القنب، وفي بعض البيئات قد يكون أكثر شيوعًا من البيئات الأخرى. تشير الدراسات وسط الشباب إلى أن 91 % لم يجربوا القنب. دراسة المسح للشباب (2020) تشير إلى أنه في المدارس الثانوية، 5 % من الفتية و3 % من الفتيات قد جربوا القنب السنة الماضية. في الدراسات العليا يقول الكثيرون إنهم جربوه؛ 19 % من الفتية و11 % من الفتيات أجابوا أنهم جربوه السنة الماضية. بالنسبة لأوسلو (2018)، فإن الأرقام أعلى من الأماكن الأخرى في البلاد وفي صفوف الثالث الثانوي VG3 في أوسلو جرّب القنب 38 من الفتية و23 % من الفتيات. وفق دراسة أوروبية حول المدارس، فإن استعمال الشباب النرويجي للقنب هو الأدنى على المستوى الأوروبي. دراسة المسح للشباب لعام 2021 تشير إلى انخفاض قليل في الاستعمال، لكن ذلك ينبغي وضعه في سياق إجراءات مكافحة العدوى أثناء كوفيد-19.',
    numbers: {
      stat1: 'من الشباب لم يجربوه',
      stat2: 'من الفتية في المدرسة الثانوية VGS قد جربوها',
      stat3: 'من الفتيات في المدرسة الثانوية VGS قد جربوها',
    },
  },

  prohibited: {
    headline: 'استعمال القنب محظور.',
    text1:
      'تؤكد لوائح قانون المخدرات على أن القنب هو مخدر، إلا أنه في قانون العقاقير فقد تقرر حظر صنع المخدرات واقتنائها وبيعها واستيرادها وتصديرها وتخزينها وحيازتها واستخدمها. جميع المنتجات التي يمكن تصنيعها من نبات القنب صُنّفت كمخدرات وهي اليوم محظورة. وكذلك القنب المصنوع كيميائيًا، والكانابينويد الصناعي، تعتبر مواد مخدرة وهي محظورة. ومخالفة قانون العقاقير جريمة يعاقب عليها. الشباب الذين يقبض عليهم وهم يستعملون القنب، غالبًا ما يتعرضون لرد فعل بديل من طرف الشرطة. قد يتلقّى الشاب إعفاء من الملاحقة القضائية، مما يعني أن الشرطة لا تباشر الإجراءات الجنائية، لكنها تطلب مثلاً فحص عينة البول ومتابعة الأخصائيين الاجتماعيين. قد يصف الطبيب بعض منتجات القنب الطبية لمجموعة صغيرة من المرضى الذين قد يستفيدون منه. هذا منظم بشكل صارم في القانون.',
    text2:
      'العديد من الأحزاب في البرلمان النرويجي يرغبون في نقل ردود فعل المجتمع على استعمال المواد المخدرة المخالفة للقانون وحيازتها للاستعمال الشخصي، من سلطات العدالة القانونية إلى مؤسسات الخدمات الصحية. فهم يرغبون بهذه الطريقة في توفير مساعدة ومتابعة أفضل. لا يزال القنب محظورًا اليوم.',
    text3:
      'قرر البرلمان النرويجي أن تعاطي المخدرات من قبل الأشخاص الذين يساعدون أو يتصلون بخدمات الطوارئ في الحالات الطارئة، مثل الجرعات الزائدة، لا ينبغي أن يُعاقبوا من قبل الشرطة.',
  },
  links: {
    headline: 'هل لا زلت تتساءل عن شيء ما؟',
    text: 'هل ترغب بمعرفة المزيد حول القنب وكيفية تأثيره في دماغك وجسمك، أو تحتاج فقط لشخص تتحدث إليه. نقدم لك هنا بعض الروابط النافعة لكي تستخدمها.',
    readmore: 'اقرأ المزيد',
    ung: 'معلومات حكومية ومضمونة الجودة للشباب. وكذلك معلومات حول القنب والمواد المخدرة الأخرى. يمكنك طرح الأسئلة والحصول على أجوبة لاستفساراتك.',
    rustelefonen:
      'خدمة معلوماتية على مستوى البلاد حول إشكالية المواد المخدرة. يحتوي على الكثير من المعلومات حول القنب. يمكنك الاتصال دون الإفصاح عن هويتك، وذلك عبر التلفون أو الدردشة chat أو الاستبيان.',
    mentalhelse:
      'منظمة تهتم بشؤون الشباب الذين يعملون في مجال مكافحة المشاكل النفسية. إذا كنت بحاجة لشخص تتحدث إليه، يمكنك الاتصال بخدمة الدردشة chat التابعة لهم.',
    helsenorge:
      'موقع حكومي على الشبكة للحصول على المعلومات الصحية للجميع. يحتوي أيضًا على معلومات حول القنب.',
  },
  about: {
    headline: 'حول الحملة',

    text: `<p>موقع Weedensenteret.no هو مركز علمي رقمي خاص بالقنب. هدفنا هو توفير معرفة متوازنة وقائمة على الحقائق. نأمل أن نتمكن من توعية الشباب حول متى وكيف تصبح المواد المخدرة مشكلة - سواء للشخص نفسه أو لأصدقائه من حوله.</p>
      <p>الأساس المعرفي لموقع الشبكة يقوم أساسًا على كتب القنب (2019)، والتقارير والمراجع المعتمدة دوليًا. تشير كتب القنب إلى أحدث الأبحاث حول القنب. وقمنا كذلك بجلب معلومات من موقع معلومات المواد المخدرة RUSinfo، والمعهد الصحي الحكومي Folkehelseinstituttet والمستشفى الجامعي لأوسلو Oslo universitetssykehus. عند اختيار محتوى موقع الشبكة، فقد استندنا أيضًا إلى الاطلاع العميق على الفئة المستهدفة والفئة المرجعية المتكونة من ممثلين عن عدة جهات كالمدارس والمؤسسات الصحية والشرطة.</p>`,
  },
  cookies: {
    short:
      'Denne nettsiden benytter <a href="https://www.helsedirektoratet.no/om-oss/om-nettstedet/besoksstatistikk-og-informasjonskapsler" target="_blank" rel="noopener">informasjonskapsler</a> (cookies).',
    long: 'Weedensenteret er en kampanje som skal informere og bevisstgjøre på når og hvordan rusmidler kan bli et problem. Vi minner om at alle produkter av cannabisplanten er regulert som narkotika og er forbudt å bruke, lage, kjøpe, selge eller oppbevare.',
  },
  ui: {
    close: 'أغلق',
    about: 'عن',
    videos: 'شاهد فيديوهات',
  },
  contentmenu: {
    forsiden: 'الصفحة الأمامية',
    planten: 'النبات',
    produkter: 'منتجات',
    kjemi: 'كيمياء',
    helse: 'التأثيرات الصحية',
    smugling: 'تهريب',
    salg: 'أُوكَازيُون',
    statistikk: 'إحصائيات',
    lovverk: 'تشريع',
    lenker: 'الروابط',
  },
};
