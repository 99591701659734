import assets from '../../assetloader';

import vertexShader from './rgb_shift.vs';
import fragmentShader from './rgb_shift.fs';

const RGBShift = {
  uniforms: {
    tDiffuse: { value: null },
    uNoiseTexture: { value: null },
    uTime: { value: 0 },
    shiftAmount: { value: 0 },
    speed: { value: 0 },
    waveFactor: { value: 0 },
    enabled: { value: true },
  },
  vertexShader,
  fragmentShader,
};

export { RGBShift };
