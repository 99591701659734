import { Vector2 } from 'three';

import vertexShader from './final.vs';
import fragmentShader from './final.fs';

const FinalShader = {
  uniforms: {
    tDiffuse: { value: null },
    uResolution: { value: new Vector2() },
  },
  vertexShader,
  fragmentShader,
  defines: {
    FXAA: true,
  },
};

export { FinalShader };
