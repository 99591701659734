export const so = {
  intro: {
    headline: 'Xarunta&shy;Weeden',
    text: 'Waa maxay maandooriyeha cannabis sidee ayuuna maskaxda saamayn ugu yeesha? Si aad waxbadan uga ogaato hoos u soco.',
  },
  plant: {
    headline: 'Dhirta maandooriyeha cannabis waa tan',
    text: 'Magaca cannabis waa magac wadareed loo yaqaanno dhammaan waxyaalaha laga soo saaro dhirta maandooriyeha cannabis sativa. Dhirtaan noocyo kala duwan ayay leedahay. Maandooriyeha cannabis waxaa siiba laga sameeyaa caleemaha ugu sarreeya iyo xabagta dhirta ee dheddigga ah.',
    cta: 'Guji qaybaha kala duwan ee geedka<br>Sidii aad u si wado aqrisashada',
    toppskudd: {
      title: 'Sawirrada',
      text: 'Biqilka sare waa qaybta ubaxa ee dhirta dheddigga. Geedaha maandooriyeha cannabis waxaa ku jira timaha qanjirrada oo yaryar oo ka buuxaan xabagyo. Timaha qanjirrada ee biqilka sare waxaa laga soo saara, xoog saarista sare ee walxaha firfircoon ee maandooriyeha cannabis kuwaas oo sababa sumoobida. Walxaha firfircoon waxaa loo yaqaanna maaddada THC. Biqilka sare waa la qallajin karaa si looga sameeyo maandooriyeha marijuana. Xabagta dhirta ee biqilka sare waxaa laga sameeyaa maandooriyeha hasch.',
    },
    stilk: {
      title: 'Jiridka geedka',
      text: 'Jiridka geedka ayaa dhirta iyo ubaxyada xambaarsan waxaana ku jira maaddada THC oo aan badnayn.',
    },
    blad: {
      title: 'Caleenta',
      text: 'Caleenta dhirta waa astaanta caalamiga ee maandooriyeha cannabis, laakiin badi ahaan waxa ku jira maaddada THC oo aan badnayn.',
    },
  },
  products: {
    headline: 'Waxsoosaaryada maandooriyeha cannabis way badan yihiin',
    text: 'Maandooriyeha cannabis waa magac wadareedka ee dhammaan waxsoosaaryada laga sameeyay geedka maandooriyeha cannabis. Teeda kale, waxaa jiraan maaddooyin soo saarkooda loo adeegsaday kiimikada oo saamayntooda la garab dhigi karo kuwa maandooriyeha cannabis. Biqilka ugu sareeyo ee dhirta dheddigga ay siiba loo isticmaala maandooriyeha marijuana, laakiin qaybo tiro badan dhirta waxay ka kooban yihiin, walxa firfircoon ee la isticmaalo samaynta maandooriyeha hasch iyo saliidaha kala duwan. Dhammaan waxsoosaaryadaas waxaa lagu xisaabiya in ay yihiin, waana mamnuuc la isticmaalo, la soo saaro, la iibsado, la sii iibiyo ama kaydiyo.',
    cta: 'Guji waxsoosaaryada kala duwan<br>Sidii aad u si wado aqrisashada',
    hasj: {
      title: 'Maandooriyeha xashiishka',
      text: 'Maandooriyeha hasch waa xabag, dareere layiig ah oo laga soo saaray dhirta, kaas oo la isku cadaadiyo haraagii kale ee geedka maandooriyeha cannabis ilaa u ka noqdo kuus buuni ah. Kuuska yar ee maandooriyeha hasch waa la jebjebiyaa kaddib waxaa lagu miliyaa tabaako kahor inta aan la cabbin.',
    },
    cannabisolje: {
      title: 'Saliidda maandooriyeha cannabis',
      text: 'Maandooriyeha cannabis saliid waa saliid dhir oo laga soo saaray dhirta maandooriyeha cannabis iyadoo la adeegsaday maaddada mileha ah. Saliidda waxay ka kooban tahay maaddada THC oo aad u badan iyo maaddada CBD oo aan badnayn. Sigaar ahaan ayaa loo, lagu darsan karaa raashinka ama cabbitaanada, ama dhibic ahaan ayaa loo qaadan karaa.',
    },
    mat: {
      title: 'Raashinka iyo cabbitaanka',
      text: 'Maandooriyeha cannabis waxaa kaloo lagu darsada cabbitaanada iyo raashinka (la cuni karo), sida tusaale dolshada brownies iyo macmacaanka. Haddii aad sidatan maandooriyeha cannabis u qaadato, waxaad marqaansanaanta dareemi doontaa sigaar-cabbida kaddib, caadi ahaan hal ilaa saddex saacadood kaddib waxaana caadi ahaan dareemi doontaa muddo aad u badan. Waxaa jirto halis wayn uu u qofku ku badsado isticmaalka maandooriyeha cannabis, maadaama dareenka maandooriyeha u soo ifbaxo muddo kaddib.',
    },
    marihuana: {
      title: 'Maandooriyeha marijuana',
      text: 'Maandooriyeha marijuana, haramo ama cagaar, waa caleen iyo ubaxa dhirta maandooriyeha cannabis. Kaddib markii caadi ahaan la soo qalajiyo iyo sidii xawaaj oo kale la soo shiido, ayaa sigaar ahaan loo cabba ama wadajir tubaakada sigaar ahaan loo cabba. Heerka maaddada THC ku jira maandooriyeha marijuana wuu kala duwan yahay, laakiin guud ahaan wuu ka hooseeyaa kan maandooriyeha hasch.',
    },
    cbdolje: {
      title: 'Saliidda maaddada CBD',
      text: 'Maaddada CBD saliid waa saliid laga soo saaray dhirta maandooriyeha cannabis. Waxay saliidda maandooriyeha cannabis ugu duwan tahay, waxay ka kooban tahay maaddada CBD oo keliya, kama koobna maaddada sidaas darteed ma sababto marqaansanaan, laakiin waa xaalad isbeddel leh. Dhammaan waxyaabaha laga soosaaray dhirta maandooriyeha cannabis, oo ay kamid yihiin waxsoosaaryada maaddada CBD, waxaa dalka Norway lagu tiriyaa in ay tahay daawo maadaama waxay ka kooban yihiin maaddada THC iyagoo noocyo kala duwan ah.',
    },
    syntetisk: {
      title:
        'Maaddooyinka Maaddooyinka cannabis ay ka kooban tahay ee aan dabiiciga ahayn',
      text: "Maandooriyeha cannabis, ama 'spice' oo aan dabiiciga ahayn, waa koox maandooriyeyaal oo wayn oo farsamagacan lagu soo saaray, saamayntooduna kama duwan kuwa maandooriyeha cannabis ee dabiiciga ah. Maaddooyinka noocaan ah badidooda saamayntooda way ka culus yihiin kuwa dabiiciga ah waxsoosaaryada maandooriyeha cannabis, sida maandooriyeha hasch iyo maandooriyeha marijuana, sidoo kale halista in qofku u la kulma dhibaato nafsani iyo dhibaatooyin kale aad ayay u sareeyaan. Waxyeelooyinka la og yahay waxaa kamid ah dhiigkar, garaaca wadnaha oo degdega, laab xanuun, wadnajoogsi, miyir la'aan, muruqgo`, kacsanaan, walaac, weeraro argagax, walwal nafsani aad u darran iyo xannuun dhinaca nafsiga u qofku horey u qabay oo sii xumaado.",
    },
  },
  chemistry: {
    headline:
      'Maandooriyeha cannabis wuxuu ka kooban yahay dhawr qaybood oo kiimika ah',
    text: 'Geedka maandooriyeha cannabis waxaa ku jira noocyo kala duwan oo ah maaddooyin kiimikada. Maaddooyinka maskaxda saamayn ku yeeshaan waxaa loo yaqaanna maaddooyinka cannabis ay ka kooban tahay. Maaddooyinka cannabis (cannabinoids) ay ka kooban tahay ee u caansan waxaa weeyaan THC iyo CBD. Waa labadaan maaddooyin, kuwa saamayn ku yeesha heerka dareenka isticmaalista maandooriyeha cannabis. Waa maaddooyin dufunta ku milmi kara, kuna hakada jirka muddo dheer hadba inta jeer aad isticmaashay. Qaadashada fudud ee maaddada THC waxaa caadi ahaan raadkeeda jirka laga heli karaa ilaa hal toddobaad gudihiisa, laakin haddiise wax badan laga isticmaalo waxaa dhici karto in raadkeeda jirka laga helo muddo ka badan hal bil.',
    cta: 'Sida aad u aqoon kororsato awgeed guji molekiyuulyaasha kala duwan',
    thc: {
      title: 'Maaddada THC',
      text: 'Maaddada THC (tetrahydrocannabinol) waa walxa nafsiyan oo qofka marqaansanaan dareensiiya. Maaddada THC waxay markii aad marqaansan tahay wax ka beddesha qaabka maskaxda u shaqayso, waxayna wax ka beddesha dabeecadaada, wacyigaaga, hab-dhaqankaaga ama aragtida dhabta ah ee noolasha. Haddii hab aad u badan loo isticmaalo maandooriyeha cannabis kaasoo ku badan maaddada THC waxaa sare u kici doonto halista cawaaqib xumada, sida xanuunka dhimirka ee degdega ah.',
    },
    cbd: {
      title: 'Maaddada CBD',
      text: 'Maaddada CBD (cannabidiol) malaha marqaamid, laakiin waxay hoos u dhigi kartaa saamaynta aan la doonayn ee maaddada THC, sida walaaca, xusuus-darrada iyo calaamadaha nafsaaniga ah. Weli aqoon badan lagama haysto maaddada CBD iyo saamaynteeda, laakiin cilmi-baaris badan ayaa lagu hawlan yahay. Dhammaan waxsoosaaryada maaddooyinka CBD, waxay ka kooban yihiin qiyaas kala duwan oo maaddada THC ah, sidaas darteed dalka Norway waxaa lagu tiriyaa sida daawooyinka kale.',
    },
  },
  health: {
    headline: 'Saamayntooda dhinaca caafimaadka',
    text: 'Maandooriyeha cannabis wuxuu dhaawac culus u geysan karaa maskaxda, siiba maskaxda qofka da`yarka ah. Maskaxda qofka da`yarka ah caadi ahaan waxay isbeddel buuxda sameeyaa markii qofku u 25 sano jir yahay. Cilmibaareyaasha waxay isku raaceen in maskaxda aan si buuxda isbeddel u samayn in maandooriyeha cannabis laga fogeeyo. Isticmaalista maandooriyeha cannabis wuxuu sare u qaadi karaa halista habiso iyo walbahaar, gaar ahaan dadka nugulsan oo bilaabay isticmaalista iyagoo da`yar. Isticmaalista maandooriyeha cannabis waxay kaloo keeni kartaa balwad.',
    cta: 'Aqriso warbixin dheeraad oo ku saabsan saamaynta caafimaadka<br>Adigoo badhanada jag-siiyo',
    korttid: {
      title: 'Saamaynta maandooriyeha ee muddada gaaban',
      text: `
          <p>Dadka cabba maandooriyeha marijuana badidooda lama kulmaan dhibaato dhinaca nafsiga.</p>
          <p>Siyaabaha dadka ugu falceliyaan maandooriyeha cannabis aad ayay u kala duwan yihiin. Dadyoowga qaarkood waxay dareemayaan yaqyaqsi, walbahaar, wadne-boodbood iyo weeraro argagax, halka qaar kale ay xasilooni dareemayaan, dawakhsanaan, qosol ama inyar oo baahi ah. Tani way kala duwanaan kartaa kolba wakhti, wayna ciribtirmaan markii marqaansanaan jirka ka ciribtirmaan.</p>
          <p>Isticmaalista maandooriyeha cannabis wuxuu kaloo saamayn ku yeesha digtoonaanshada iyo ka jawaabista, sidaas darteed ma wanaagsan in gaari la wado markii qofku u marqaansan yahay. Markii maandooriyeha la isticmaalo wadajir maandooriyeyaal kale iyo khamriga, way adkaanaysaa saamayntooda waxayna sababi karaan falceelinno aan la filanayn.</p>
        `,
    },
    langtid: {
      title: 'Saamayntooda muddada dheer',
      text: `
          <p>Isticmaalista joogtada ah ee maandooriyeha cannabis ee muddada dheer waxay yeelan karaan saamayn muddo dheer ah. Saamaynta muddada dheer waxaa kamid ah hoos u dhaca kartida garashada, sida hoos u dhaca awoodda fiirogaar-siinta, xasuus-darro iyo hoos u dhaca kartida xal u helista arrin jirta.</p>
          <p>Maadaama maskaxda da'yarta aynan si buuxda u kobcin, waxay dhaawacyada noocaas ah noqon karaan kuwa joogto ah, haddii aad isticmaasho maandooriyeha cannabis markii da' daada ay yartahay. Isticmaalista muddada dheer waxay kaloo sababi kartaa dhibaatooyinka nafsaaniga ah, sida walbahaar iyo habiso iyo calaamadaha nafsaaniga ah sida mala-awaal iyo dhalanteed. Xaaladdaan waxay siibo khuseeysa dadka falxummada u nugulsan. Xaaladaha ugu xun ee u sabab ah isticmaalista maandooriyeha cannabis ee muddada dheer, waxaa weeyaan walwal nafsani aad u darran sida schizophrenia.</p>
        `,
    },
    avhengighet: {
      title: 'Ku tiirsanaan',
      text: `
          <p>Haddii adigoo da'yar aad bilaawdo inaad cabto maandooriyeha cannabis oona badsato, waxaa dhici karto inaad ku tiirsanaato, macnaha balwad ku noqoto. Haddii aad cabtid wuxuu jirkaaga bilaabayaa in u aad ugu adkaysto, taasoo sababi doonto inaad marqaamid darteed aad kolba badsato isticmaalista maandooriyeha cannabis.</p>
          <p>Macnaha ku tirsanaanshada waxaa weeyaan, in markii aad isku daydo inaad joojiso isticmaalista inaad dareemi doonto caagganaan oo noocyo kala duwan ah. Calaamadaha caadi ahaan aad dareemi doonto waxaa kamid ah isdejin la'aan, dhibaatada dhinaca hurdada, walbahaar iyo xoogsaaris-darro. Ku tirsanaanshada isticmaalista maandooriyeha cannabis qasab ma ahan in ay u sabab tahay marqaansanaanta, ee waxay noqon kartaa dib u gurasho iyo sidii aad "caafimaad wanaag" ku dareemi lahayd.</p>
          <p>Dhinaca kale ee ku tirsanaanshada waxaa weeyaan, waxay saamayn ku yeelan kartaa qodobka noolashaada ugu muhimsan, iyo inaad maandooriyeha cannabis mudnaanta koowaad siiyo in ka badan dhaqdhaqaaqyada kale. Waayahaan dambe waxaa dhacdo in tiro aad badan ay raadsadaan in laga daryeelo ku tirsanaanshada maandooriyeha cannabis waa haddii la barbardhigo sidii berri hore ay ahayd.</p>
        `,
    },
  },
  smuggling: {
    headline: 'Maandooriyeha cannabis halkee laga keenaa?',
    text1:
      'Berri hore waxaa dhirta lagu abuuri jiray dalalka cimiladooda ay kulul yihiin, siiba Qaaradda Asien, Waqooyiga Afrika iyo Bartamaha Ameerika, halkaas ayaa weli aad loogu abuura. Maandooriyeha hasch oo gacanta loogu dhigay dalka Norway, waxaa siiba laga keenay dalka Morocco kaddib si qarsoodi ahaa waxaa la soo marsiiyay dalalka Spain iyo Holland, laakiin tiro aad u badan oo ah maandooriyeha cannabis laga isticmaalo qaaradda Yurub waxaa lagu abuura halkaan. Geedaha maandooriyeha cannabis badan ayaa dalka Norway laga helay.',
    text2:
      'Abuurista maandooriyeha cannabis waxay bay`adda iyo bulshada ba ku yeelan kartaa cawaaqib xun. Waxay ka hortegi kartaa helista biyaha u aadanaha u baahan yahay waxayna kaalin ka geystaan kartaa sii daynta kiimikada waxyeesho bay`adda dabiiciga. Ka ganacsashada sharcidarrada ee maandooriyeha waa daqliga muhimka u ah ururrada dambiileyaasha iyo kuwa argagixisada. Waxay ku maalgeliyaan dhaqdhaqaaq kale oo sharcidarro ah, sida ka ganacsiga dadka iyo in ku soo iibsadaan maandooriyeyaal kale iyo hub.',
  },
  smugglingGame: {
    text: 'Badhanka guji sidii aad u hagto dayuuradda dalka Norway u soo socoto. Iskuday inaad ku dhufato goobaabinta buluugga ah. Hagida darteed riix dhinaca midigta ama bidixda ee shaashada.',
    cta: 'Ciyaar',
    game: {
      text1:
        'Maandooriyeha hasch oo gacanta lagu dhigay dalka Norway badi ahaan waxaa laga keenay dalka Morocco.',
      text2:
        'Kaddib si qarsoodi ahaa waxaa la soo marsiiyay dalalka Spain iyo Holland.',
      text3:
        'Tiro aad u badan oo ah maandooriyeha cannabis laga isticmaalo qaaradda Yurub waxaa lagu abuura halkaan.',
      text4:
        'Teeda kale dhawr geedaha maandooriyeha cannabis ayaa dalka Norway laga helay.',
    },
  },
  sale: {
    headline: 'Maandooriye ka xoog badan kii berri hore',
    text: 'Maandooriyeha hasch iyo maandooriyeha marijuana waxay noqdeen kuwa aad u xoog badan haddii la barbardhigo kuwii berri hore waayo waxaa lagu kordhiyay maaddada THC. Celcelis ahaan qaybta maaddada THC ku jirta maandooriyeha hasch ayaa ka kordhay taasoo toban sano kahor ahayd qiyaastii boqolkiiba 10%, maanta qiyaastii waa boqolkiiba 30%, laakiin qaybta maaddada THC oo la diiwaan geliyay boqolkiiba waa 45%. Korniinka maaddada THC wuxuu sare u qaada halista dhaawac caafimaad ee goor dambe. Hagayaasha kala duwan ma kaaga muuqdaan heerka qaybta maaddada?',
    cta: 'Guji wadajirka si aad u aragto jawaabta',
    joints:
      'Xitaa haddii celceliska dalka Norway horeba u sare ahaa 30%, waxaa la shaaciyay heer u ahaa boqolkiiba 45%.',
  },
  statistics: {
    headline:
      'Waa immisa tirada dadka ku nool dalka Norway oo isticmaala maandooriyeha cannabis?',
    text: 'Dad tiro badan waxaa laga yaaba in ay u maleeyaan in qof kasta oo tijaabiyay maandooriyeha cannabis, waxaa dhici karto in bay`adaha qaarkood ay sidaas tahay haddii la barbardhigo kuwa kale. Cilmibaaris la sameeyay waxaa lagu shaaciyay in boqolkiiba 91% ee dhallinyarada in aynan weligood tijaabinin maandooriyeha cannabis. Cilmibaaris Ungdata (2020) waxaa lagu shaaciyay in boqolkiiba 5% ee wiilasha iyo boqolkiiba 3% gabdhaha u dhigta dugsiyada dhexe ay tijaabiyeen maandooriyeha cannabis sannadihii ugu dambeeyeen. Waxaa la yiri in ardayda dhigata dugsiyada sare ay boqolkiiba 19% wiilasha iyo boqolkiiba 11% gabdhaha ay tijaabiyeen maandooriye sannadkii ugu dambeeyay. Tirada wuu ka sarreeyaa meelaha kale ee dalka iyo fasalka saddexaad ee dugsiga sare ee Oslo, 38% wiilasha iyo 23% gabdhaha ayaa isticmaalay maandooriyaha cannabis. Baaritaan iskuullada dalalka Yurub la sameeyay, dhallinyarada dalka Norway isticmaal maandooriyeha cannabis waa tirada ugu hooseeyso dalalka Yurub ku yaalaan. Ungdata tirada 2021 waxaa lagu shaaciyay hoos u dhaca tirada isticmaal, laakiin taasi waxaa sabab u noqon karo talaabooyinka laga qaaday halista infeekshanada xilligii Covid-19.',
    numbers: {
      stat1: 'dhallinyarada <strong>ma</strong> tijaabinin.',
      stat2: 'wiilasha dhigta fasalada saree e dugsiga sare ayaa istimaalay.',
      stat3: 'gabdhaha fasalada saree e dugsiga sare ayaa istimaalay.',
    },
  },

  prohibited: {
    headline: 'Isticmaalista maandooriyeha cannabis waa mamnuuc',
    text1:
      'Sharciga maandooriye waxaa ku xusan in maandooriyeha cannabis u yahay daroogo, sharciga wuxuu faraya in ay mamnuuc tahay soosaarista, keensashada, iibinta, dibad ka keensashada, dibad u dhoofinta, kaydinta, haysashada iyo isticmaalka maandooriyeyaasha. Dhammaan waxsoosaaryada laga sameeyay dhirta maandooriyeha cannabis waa daroogo waana ka mamnuuc dalka. Maandooriyeha cannabis ee kiimikada laga sameeyay, maaddooyinka Maaddooyinka cannabis ay ka kooban tahay gacmaha lagu farsameeyay, xitaa iyagu waa daroogo waana ka mamnuuc dalka. Qofkii qodobada sharciga jebiyo sida hadba sharciga waafaqsan ciqaab ayuu mutaysan yahay. Dhallinyarada lagu qabto iyagoo maandooriyeha cannabis isticmaalayo badi ahaan arrin booliis ayay noqon doontaa. Waxaa dhici karto in da`yarka aan dacwo lagu soo oogin, macnaha in usan booliiska ku dhaqaaqin baaritaan, laakin waxaa laga qaada shaybaarka kaadida iyo in Maamulka adeegga bulshada u arrintiisa dabaggal ku sameeyo. Waxaa dhici karto in koox bukaan-socod oo aan tiradooda badnayn u qof dhakhtar ah u oggolaado in ay isticmaalaan waxsoosaaryada maandooriyeha cannabis, waa haddii ay ka faa`iidaysan karaan. Qododbadaan si adag ayay sharciga ugu xusan yihiin.',
    text2:
      'Dhawr xisbiyada dalka ee baarlamaanka qaranka Norway, waxay soo jeediyeen talo ah in mas`uuliyadda bulshada ee isticmaalka iyo haysashada maandooriyeyaasha sharcidarrada, oo shaqsi ahaan loo isticmaalo, in laga wareejiyo laamaha cadaaladda kaddib lagu wareejiyo laamaha daryeelka bukaanada. Waxay doonayaan in sidaas lagu sugo caawinaadda iyo dabagalka arrimaha noocaas ah. Isticmaalista maandooriyeha cannabis weli waa ka mamnuuc dalka.',
    text3:
      'Baarlamaanka dalka wuxuu go`aamiyay in si xun u isticmaalka maandooriyeha ee dadka caawiya ma markii xaaladda degdeg ah waca adeegyada gurmadka, sida tusaale haddii isticmaalaha u qaato qiyaas xad dhaaf ah, in ciidanka booliska usan ciqaabin.',
  },
  links: {
    headline: 'Su`aallo dheeraad miyaa na waydiin lahayd?',
    text: 'Ma u baahan tahay warbixin dheeraad oo ku saabsan maandooriyeha cannabis iyo sida u saamayn ugu yeesho maskaxda iyo jirka, ama ma u baahan tahay oo keliya qof aad la hadasho? Hoos waxaad ka heleysaa isku-xireyaal oo aad ka faa`iidaysan karto.',
    readmore: 'Aqriso warbixin dheeraad',
    ung: 'Guud iyo tayada sugan waa warbixin dhallinyarada loogu talagalay. Warbixin ku saabsan maandooriyeha cannabis iyo maandooriyeyaasha kale. Su`aallo ayaa na waydiin kartaa, waxaana heli kartaa jawaabtooda.',
    rustelefonen:
      'War faafinta heer qarmeed ee dhibaatooyinka maandooriyeyaasha. Waxaan helay warbixin buuxda oo ku saabsan maandooriyeha cannabis. Adigoo magacaaga qariso ayaa nalaga soo xiriiri kartaa khadka taleefanka, nala sheekaysan kartaa ama ka jawaabi kartaa su`aalaha qoraal-waydiimaha.',
    mentalhelse:
      'Waa urur samafal ah oo loogu talagalay dhallinyarada ku hawlan kahortegista caafimaad-darrada nafsiga. Haddii aad u baahan tahay qof aad la hadasho, iyaga ayaa la xiriiri kartaa adigoo isticmaala adeegga sheekada ee iyaga u gaarka ah.',
    helsenorge:
      'Waa goob Internet oo loogu talagalay dadwaynaha in ay ka aqristaan warbixin ku saabsan caafimaadka. Isla goobtaas waxaa kaloo laga aqrisan karaa warbixin ku saabsan maandooriyeha cannabis.',
  },
  about: {
    headline: 'Ku saabsan Ololaha',

    text: `<p>Weedensenteret.no waa goob farsamada digitaalka ku dhisan oo ah xarunta cilmiga maandooriyeha cannabis. Hadafkeena wuxuu yahay in aan soo bandhigno aqoon dhab ah oo nuxur leh. Waxaan rajeynaynaa in aan sare u qaadno aqoonta ku saabsan dhibaatooyinka maandooriyeyaasha ee dhallinyarada looga talagalay iyo sida saamayn ugu yeeshaan – iyaga iyo bay'adda agagaarkooda.</p>
      <p>Kaydka aqoonta ee barta internetka guud ahaan waxaa gundhig u ah, buugga Maandooriyeha cannabis (2019), warbixinno iyo suugaan caalami ah oo la aqoonsan yahay. Buugga ka sheekaynayo maandooriyeha cannabis, wuxuu tixraacayaa cilmibaarista maandooriyeha cannabis ee ugu dambaysay. Teeda kale, waxaan warbixin ka soo xigsanay RUSinfo, Machadka Caafimaadka Dadweynaha iyo Isbitaalka Jaamacadda Oslo. Markii aan xulanaynay xogta ku qoran bogga internetka waxaan sidoo kale ka bilownay, hawlgallada aragtida kooxaha bartilmaaneedka iyo kooxaha la tixraaco oo ka kooban wakiilada sida tusaale iskuullada, xarumaha adeegyada caafimaadka iyo ciidanka booliiska.</p>`,
  },
  cookies: {
    short:
      'Denne nettsiden benytter <a href="https://www.helsedirektoratet.no/om-oss/om-nettstedet/besoksstatistikk-og-informasjonskapsler" target="_blank" rel="noopener">informasjonskapsler</a> (cookies).',
    long: 'Weedensenteret er en kampanje som skal informere og bevisstgjøre på når og hvordan rusmidler kan bli et problem. Vi minner om at alle produkter av cannabisplanten er regulert som narkotika og er forbudt å bruke, lage, kjøpe, selge eller oppbevare.',
  },
  ui: {
    close: 'Xir',
    about: 'Ku saabsan',
    videos: 'Daawo muuqaalo',
  },
  contentmenu: {
    forsiden: 'Bogga hore',
    planten: 'Geedka',
    produkter: 'Alaabta',
    kjemi: 'Kiimikada',
    helse: 'Saamaynta caafimaadka',
    smugling: 'Tahriibta',
    salg: 'Iibka',
    statistikk: 'Tirakoobka',
    lovverk: 'Sharci',
    lenker: 'Xiriirinta',
  },
};
