import {
  Object3D,
  TextureLoader,
  CubeTextureLoader,
  PerspectiveCamera,
  MeshBasicMaterial,
  MeshLambertMaterial,
  MeshPhongMaterial,
  Matrix4,
  Color,
  BoxBufferGeometry,
  Mesh,
  Vector3,
} from 'three';
import gsap from 'gsap';

import BaseInstance from './baseinstance';
import dispatcher from '../dispatcher';
import assets from '../../assetloader';
import pointer from '../pointer';
import camera from '../camera';
import raycast from '../raycast';

import jointTexture from 'textures/joint-texture.jpg';
import joint from 'gltf/joint.glb';

import { randBetween } from '../../utils';

import translation, { RTL_LANGUAGES } from '../../translation';

const NUM_JOINTS = 7;
const RADIUS = 5;
const MAX_ANGLE = Math.PI / 2;
const JOINT_SCALE = 230;

const JOINT_TYPES = {
  nb: [
    'Ren marihuana',
    'Marihuana blandet med tobakk',
    'Hasj blandet med tobakk',
  ],
  nn: [
    'Rein marihuana',
    'Marihuana blanda med tobakk',
    'Hasj blanda med tobakk',
  ],
  smi: [
    'Buhtes marihuana',
    'Marihuana seaguhuvvon duhpáhiin',
    'Hášša seaguhuvvon duhpáhiin',
  ],
  en: [
    'Pure marijuana',
    'Marijuana mixed with tobacco',
    'Hash mixed with tobacco',
  ],
  ar: [
    'الماريجوانا النقية',
    'الماريجوانا الممزوجة بالتبغ',
    'حشيش مخلوط بالتبغ',
  ],
  so: [
    'Marijuana saafi ah',
    'Marijuana lagu qasay tubaakada',
    'Xashiish lagu qasay tubaakada',
  ],
  ur: ['خالص چرس', 'تمباکو کے ساتھ ملا ہوا چرس', 'تمباکو کے ساتھ ملا ہوا ہیش'],
};

const JOINT_TEXT = {
  nb: [
    'Gjennomsnittlig THC i marihuana i Norge er på 12 %, men det varierer mellom 2 % og 29 %.',
    'Gjennomsnittlig THC i marihuana i Norge er på 12 %, men det varierer mellom 2 % og 29 %.',
    'Selv om snittet i Norge allerede er høyt på 30 % har det blitt målt så høyt som 45 %.',
  ],
  nn: [
    'Gjennomsnittleg THC i marihuana i Noreg er på 12 %, men det varierer mellom 2 % og 29 %.',
    'Gjennomsnittleg THC i marihuana i Noreg er på 12 %, men det varierer mellom 2 % og 29 %.',
    'Sjølv om snittet i Noreg allereie er høgt på 30 %, har det blitt målt så høgt som 45 %.',
  ],
  smi: [
    'Gaskamearalaš THC-dássi marihuanas Norggas lea 12 %, muhto rievddada gaskal 2 % ja 29 %.',
    'Gaskamearalaš THC-dássi marihuanas Norggas lea 12 %, muhto rievddada gaskal 2 % ja 29 %.',
    'Vaikke vel Norgga gaskamearalaš lohku lea 30 % allat, lea mihtiduvvon nu alla lohku go 45 %.',
  ],
  en: [
    'The average THC in marijuana in Norway is 12%, but it varies between 2% and 29%.',
    'The average THC in marijuana in Norway is 12%, but it varies between 2% and 29%.',
    'Although the average in Norway is already high at 30%, it has been measured at as high as 45%.',
  ],
  ar: [
    'يبلغ متوسط رباعي هيدروكانابينول (THC) في الماريجوانا في النرويج 12%، لكنه يتراوح بين 2% و29%.',
    'يبلغ متوسط رباعي هيدروكانابينول (THC) في الماريجوانا في النرويج 12%، لكنه يتراوح بين 2% و29%.',
    'وعلى الرغم من أن المتوسط في النرويج مرتفع بالفعل بنسبة 30%، فقد وصل إلى 45%.',
  ],
  so: [
    'Marijuana THC ee ugu dambaysay ee Norway waa 12%, laakiin waxaa ka mid ah 2% ilaa 29%.',
    'Marijuana THC ee ugu dambaysay ee Norway waa 12%, laakiin waxaa ka mid ah 2% ilaa 29%.',
    'Waxaana loo qaaday inay ka yar yihiin 45%.',
  ],
  ur: [
    'ناروے میں چرس کی اوسط THC 12% ہے، لیکن یہ 2% اور 29% کے درمیان مختلف ہوتی ہے۔',
    'ناروے میں چرس کی اوسط THC 12% ہے، لیکن یہ 2% اور 29% کے درمیان مختلف ہوتی ہے۔',
    'اگرچہ ناروے میں اوسط پہلے ہی 30% سے زیادہ ہے، لیکن اسے 45% تک ماپا گیا ہے۔',
  ],
};

export default class Sale extends BaseInstance {
  constructor(opts) {
    super(opts);
  }

  init() {
    this.container = new Object3D();
    this.add(this.container);
    this.container.position.x = window.innerWidth > 768 ? 2 : 0;
    this.container.position.y = window.innerWidth > 768 ? 3 : -2;

    this.contentShowing = false;
    this.activeObject = null;

    this.jointRouletteEl = document.querySelector('#jointRoulette');
    this.titleEl = this.jointRouletteEl.querySelector('h3');
    this.textEl = this.jointRouletteEl.querySelector('p');
    this.closeBtn = this.jointRouletteEl.querySelector('button');

    this.noTouch = document.body.classList.contains('no-touch');

    this.closeBtn.addEventListener('click', () => {
      this.hideContent();
    });

    this.setupJoints();
    this.setupAnimations();

    dispatcher.on('pointerClick', () => {
      raycast.intersect(this.jointContainer.children, (hit) => {
        if (hit && this.active && !this.contentShowing) {
          this.contentShowing = true;

          const { object } = hit;
          if (object.isMesh) {
            const parent = object.parent;
            gsap.to(parent.rotation, {
              duration: 0.6,
              z: 2.5,
              ease: 'back.out',
              onComplete: () => {
                this.createTitle();
                this.showContent();
              },
            });
            gsap.to(parent.position, {
              duration: 0.6,
              z: 1.5,
              ease: 'back.out',
            });
            this.activeObject = parent;
          }
        }
      });
    });
  }

  createTitle() {
    const lang = translation.lang;
    const thcValue = Math.round(randBetween(1, 46));

    let type = null;
    let text = null;

    if (thcValue > 29) {
      type = JOINT_TYPES[lang][2];
      text = JOINT_TEXT[lang][2];
    } else {
      let index = Math.floor(Math.random() * (JOINT_TYPES[lang].length - 1));
      type = JOINT_TYPES[lang][index];
      text = JOINT_TEXT[lang][index];
    }

    const title = `${type} (${thcValue} % THC)`;
    this.titleEl.textContent = title;
    this.textEl.textContent = text;

    const isRTL = RTL_LANGUAGES.includes(lang);

    if (isRTL) {
      this.titleEl.setAttribute('dir', 'rtl');
      this.textEl.setAttribute('dir', 'rtl');
    } else {
      this.titleEl.removeAttribute('dir');
      this.textEl.removeAttribute('dir');
    }
  }

  showContent() {
    gsap.set(this.jointRouletteEl, { display: 'block', opacity: 1 });
    gsap.from(this.jointRouletteEl, {
      duration: 0.5,
      opacity: 0,
      scale: 0.8,
      ease: 'power4.out',
    });
  }

  hideContent() {
    gsap.to(this.jointRouletteEl, {
      duration: 0.5,
      opacity: 0,
      scale: 0.8,
      ease: 'power4.out',
      clearProps: 'all',
    });
    gsap.to(this.activeObject.rotation, {
      duration: 0.6,
      z: 0,
      ease: 'back.out',
    });
    gsap.to(this.activeObject.position, {
      duration: 0.6,
      z: 0,
      ease: 'back.out',
    });
    this.contentShowing = false;
  }

  setupJoints() {
    this.jointContainer = new Object3D();
    this.jointContainer.position.set(-RADIUS * 0.5 - 0.8, -RADIUS * 0.5 - 3, 0);
    const scale = window.innerWidth < 768 ? 0.7 : 1;
    this.jointContainer.scale.set(scale, scale, scale);

    const jointModel = assets.get(joint).scene.children[0].children[0];
    const jointMap = assets.get(jointTexture);
    const mat = new MeshBasicMaterial({ color: 'white', map: jointMap });

    // jointModel.scale.set(0.001, 0.001, 0.001);
    jointModel.scale.set(JOINT_SCALE, JOINT_SCALE, JOINT_SCALE);
    jointModel.rotation.set(-Math.PI / 2, Math.PI / 2, 0);

    jointModel.traverse((child) => {
      if (child.isMesh) child.material = mat;
    });

    for (let i = 0; i < NUM_JOINTS; i++) {
      const clone = jointModel.clone();
      const angle = (i / NUM_JOINTS) * MAX_ANGLE;
      const x = Math.cos(angle) * RADIUS;
      const y = Math.sin(angle) * RADIUS;
      clone.position.set(x, y, 0);
      clone.rotation.y -= angle;

      this.jointContainer.add(clone);
    }

    this.container.add(this.jointContainer);
  }

  setupAnimations() {
    this.inAnim = gsap.timeline({ paused: true });
    this.outAnim = gsap.timeline({ paused: true });

    if (window.innerWidth > 768) {
      this.container.position.y = 0;

      this.inAnim.from(
        '#sale',
        { y: '30vh', opacity: 0, duration: 1.5, ease: 'power2.out' },
        1.5
      );
      this.outAnim.to(
        '#sale',
        { y: '-30vh', opacity: 0, duration: 0.3, ease: 'power2.in' },
        0
      );

      this.outAnim.to(this.container.position, { y: 2, ease: 'none' }, 0);
    } else {
      this.inAnim.fromTo(
        '#sale',
        {
          y: '100vh',
        },
        {
          y: '-20vh',
          duration: 2,
          ease: 'none',
        },
        0
      );

      this.outAnim.fromTo(
        '#sale',
        {
          opacity: 1,
          y: '-20vh',
        },
        {
          y: '-120vh',
          duration: 2,
          opacity: 0,
          ease: 'none',
        },
        0
      );
    }
  }

  onPointerMove() {
    if (!this.active || !this.noTouch || this.contentShowing) return;

    raycast.intersect(this.jointContainer.children, (hit) => {
      if (!hit) {
        this.jointContainer.traverse((child) => {
          if (child.isGroup) {
            gsap.to(child.scale, {
              duration: 0.4,
              x: JOINT_SCALE,
              y: JOINT_SCALE,
              z: JOINT_SCALE,
              ease: 'power2.out',
            });
            child.userData.hover = false;
          }
        });
        return;
      }

      const { object } = hit;
      if (object.isMesh) {
        const parent = object.parent;
        const { userData } = parent;
        if (!userData.hover) {
          const scale = JOINT_SCALE * 1.15;
          userData.hover = true;
          gsap.to(parent.scale, {
            duration: 0.6,
            x: scale,
            y: scale,
            z: scale,
            ease: 'power4.out',
          });
        }
      }
    });
  }

  onRaf() {
    if (!this.active) return;

    this.rotation.y = -pointer.normEased.x * 0.4;
    this.rotation.x = -pointer.normEased.y * 0.4;
  }
}
